import React from "react";
import DatePicker from "react-date-picker";
import FilterDropdown from "./FilterDropdown";

const MyReportsFilter = ({ filters, updateFilter, clearFilter, handleSubordinateFilterSelection, minDate }) => {

  return (
    <div className="filter d-flex align-items-center justify-content-end gap-2 p-1">
      {Object.hasOwn(filters, "dateFilter") && (
        <div>
          <span className="mx-1">Date:</span>
          <DatePicker
            className="date-picker border border-2"
            next2Label={null}
            nextLabel={null}
            prev2Label={null}
            prevLabel={null}
            calendarIcon={<i className="bi bi-caret-down-fill"></i>}
            clearIcon={null}
            value={filters.dateFilter}
            onChange={(value) => {
              updateFilter("dateFilter", value);
            }}
            maxDate={new Date()}
            minDate={minDate}
          />
        </div>
      )}
      {Object.hasOwn(filters, "dateFilter") &&
        Object.hasOwn(filters, "monthFilter") && (
          <span className="text-danger">or</span>
        )}
      {Object.hasOwn(filters, "monthFilter") && (
        <div>
          <span className="mx-1">Month:</span>
          <DatePicker
            className="date-picker border border-2"
            next2Label={null}
            prev2Label={null}
            calendarIcon={<i className="bi bi-caret-down-fill"></i>}
            clearIcon={null}
            maxDetail="year"
            value={filters.monthFilter}
            onChange={(value) => {
              updateFilter("monthFilter", value);
            }}
            maxDate={new Date()}
            minDate={minDate}
          />
        </div>
      )}
      {filters.subordinates && (
        <FilterDropdown
          name="subordinates"
          value={filters.subordinates}
          direction="down"
          icon="caret-down-fill"
          handleFilterSelection={handleSubordinateFilterSelection}
          className="restrict-width border border-2 p-1 w-100 d-flex flex-row"
        />
      )}
      <button
        type="button"
        className="filter-close-btn button"
        onClick={clearFilter}
      >
        <i className="bi bi-x"></i>
      </button>
    </div>
  );
};

export default MyReportsFilter;
