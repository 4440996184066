import React from "react";
import { dateInUIFormat } from './../utils/dateUtils';

const ReportHeading = (props) => {
  function status() {
    if(props.subordinateReport) {
      return <div className="d-flex align-items-center">
          <h5 className="report-heading">{props.submitted ? "Received" : "Not Received"}</h5>
              <i className={`bi bi-circle-fill ms-2 ${props.submitted? "text-success" : "text-danger"}`}></i>
        </div>
    } else {
      return <div className="d-flex align-items-center">
          <h5 className="report-heading">Status: {props.statusText}</h5>
          {props.submitted === false && (
            <button
              className="create-btn button ms-2"
              onClick={() => props.onclick()}
            >
              <i
                className={`bi bi-${
                  props.expand ? "dash-square" : "plus-square"
                }`}
              ></i>
            </button>
          )}
        </div>
    }
  }

  return (
    <div className="d-flex justify-content-between align-items-center py-3">
      {props.subordinateReport && props.user?.name && 
        (
        <div className="report-profile d-flex align-items-center gap-2">
        <div className="nav-profile d-flex align-items-center pe-0">
          {props.user?.imgURL ? (
            <img src={props.user?.imgURL} alt="" className="rounded-circle" />
            ) : (
            <span className="profile-picture">{props.user.name?.slice(0, 1).toUpperCase()}</span>
            )}
        </div>
        <h5 className="report-heading">{props.user?.name}</h5>
        </div>
      )}
      {props.date && <h5 className="report-heading">Date: {dateInUIFormat(props.date)}</h5>}
      {props.postedOn && (
        <h5 className="report-heading">Posted On: {dateInUIFormat(props.postedOn)}</h5>
      )}
      {/* {props.sentTo && !props.subordinateReport && (
        <h5 className="report-heading">Sent to: {props.sentTo.join(", ")}</h5>
      )} */}
      {status()}
    </div>
  );
};

export default ReportHeading;
