import FilterDropdown from "./FilterDropdown";
import Tooltip from "./Tooltip";

const UserForm = (props) => {
  const activeUsers = props.userArray;
  const managerList = activeUsers.filter(user => user.id !== props.id);

  return (
    <div className="pt-3 d-flex flex-column gap-1">
      <label className="form-label mt-3" id="user-name">
        Name
        <input
          type="text"
          className="form-control"
          name="name"
          value={props.formState.name}
          onChange={props.handleInputChange}
          required
        />
      </label>
      <label className="form-label" id="user-email">
        E-mail
        <input
          type="email"
          className="form-control"
          name="email"
          value={props.formState.email}
          onChange={props.handleInputChange}
          disabled={!props.createUser}
        />
      </label>
      <label className="form-label" id="user-role">
        Role
        <select
          className="form-select"
          name="role"
          value={props.formState.role}
          onChange={props.handleInputChange}
        >
          <option value="standard">Standard</option>
          <option value="admin">Admin</option>
        </select>
      </label>
      <label className="form-label" id="user-manager">
        Manager
        <select
          className="form-select"
          name="manager"
          value={props.formState.manager}
          onChange={props.handleInputChange}
        >
          <option value="">Select Manager</option>
          {managerList.map((manager, i) => (
            <option key={i} value={manager.code}>
              {manager.name}
            </option>
          ))}
        </select>
      </label>
      <div className="form-label" id="user-recipients">
        <div className="d-flex justify-content-between">
          <span>Additional Recipients</span>
          <Tooltip
            icon="info-circle"
            text="Add additional recipients to get email of daily tasks report of this user."
          />
        </div>
        <FilterDropdown
          name="recepients"
          hintText="Select Recipients"
          value={props.formState.recepients}
          handleFilterSelection={props.handleOptionSelection}
          icon={null}
          className="form-select"
          direction="up"
        />
      </div>
      <div className="text-center">
        <button
          onClick={props.handleFormSubmission}
          id="submit-btn"
          disabled={props.shouldDisableSubmitButton()}
          className="btn px-5 py-2 mt-2 mb-3 rounded"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default UserForm;
