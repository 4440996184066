import { useCallback } from "react";
import { useAlert } from "../context/AlertContext";
import { apiAuthenticate } from "../utils/apiAuthenticator";
export const useApiCall = () => {
  const { showErrorAlert } = useAlert();

  const handleError = useCallback((error) => {
    if (error.status === 401) { //this case is for on expirty token
      showErrorAlert("Some thing went wrong! Please try Again.");
      apiAuthenticate()
      return true;
    } else if ( error.status === 3 ) {
      showErrorAlert("Some error occured! Please try again");
      return true;
    }
    return false;
  }, [showErrorAlert])

  const apiCallMiddleware = useCallback(async (apiFunction, ...args) => {
    try {
      const response = await apiFunction(...args);
      return response;
    } catch (error) {
      const isErrorHandled = handleError(error);
      if(!isErrorHandled){
        throw error;
      }
    }
  }, [handleError]);
  return apiCallMiddleware;
};