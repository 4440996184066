import React from "react";
import top from "../assets/images/top.png";
import bottom from "../assets/images/bottom.png";
import dashboard from "../assets/images/dashboard.png";
import { useKeycloak } from "@react-keycloak/web";
function Home() {
  const { keycloak } = useKeycloak();
  return (
    <div>
      <div className="bg-primary" style={{ padding: "100px 0px 40px" }}>
        <div className="container d-flex flex-column-reverse flex-lg-row align-items-start justify-content-between gap-5">
          <div className="col-lg-6">
            <h1 className="lh text-light">
              Streamline Daily Tasks Reporting, Timesheets, and Supercharge Team
              Collaboration
            </h1>
            <p className="home-text mt-4 text-light">
              Introducing our innovative SaaS solution designed to revolutionize
              daily task reporting, timesheet management, and amplify team
              collaboration. We’ve redefined the way teams report, track time,
              collaborate, and succeed. Our cutting-edge SaaS solution is
              tailored to transform your team’s daily task reporting and
              timesheet tracking, empowering seamless communication and
              fostering a culture of productivity.
            </p>
            <button
              id="submit-btn"
              className="btn bg-light text-primary px-4 py-2 rounded-pill mt-5"
              onClick={() => {keycloak.login({redirectUri: window.location.origin + "/dashboard"})}}
            >
              Sign in Now!
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <img
              className="rounded-5 shadow"
              style={{
                width: "60%",
              }}
              src={top}
              alt="Paper"
            ></img>
          </div>
        </div>
      </div>
      <div className="bg-white" style={{ padding: "40px 0px" }}>
        <div className="container d-flex align-items-center justify-content-center">
          <div className="d-flex flex-column justify-content-center ">
            <h1 className=" mb-5 ">
              Elevate Your Team’s Productivity with Streamlined Daily tasks
              Reporting
            </h1>
          </div>
        </div>
        <div className="container d-flex flex-column align-items-start flex-lg-row justify-content-between gap-5 ">
          <div className="col-lg-6 d-flex  align-content-center justify-content-center rounded-3">
            <img
              className="rounded-2 shadow"
              style={{ width: "90%" }}
              src={dashboard}
              alt="Dashboard UI"
            />
          </div>
          <div>
            <h3 className="lh-1 ">
              Effortless Reporting, Elevated Productivity:
            </h3>
            <p className="home-text mt-2">
              Say goodbye to cumbersome reporting processes. Our intuitive
              platform simplifies daily task reporting and timesheet management,
              allowing team members to effortlessly log their tasks, track their
              hours, outline future plans, and request assistance—all within a
              single, user-friendly interface.
            </p>
            <h3 className="lh-1 mt-4">Tailored for Your Workflow:</h3>
            <p className="home-text mt-2">
              We understand that every company operates uniquely. That’s why our
              white-label solution offers complete customization, ensuring a
              perfect fit for your organization’s workflow and needs.
            </p>
            <h3 className="lh-1 mt-4">
              Collaboration and Time Tracking at Its Core:
            </h3>
            <p className="home-text mt-2">
              Collaboration is key to success. Our tool facilitates a
              collaborative environment where team members can seek support,
              provide updates, and work cohesively towards shared objectives.
              Additionally, integrated timesheet functionality ensures precise
              tracking of work hours, enhancing accountability and productivity.
            </p>
          </div>
        </div>
        <div className="container">
          <h3 className="lh-1 mt-4">Insights for Informed Decision-Making:</h3>
          <p className="home-text mt-2">
            Gain invaluable insights into your team’s activities and future
            plans. This visibility enables better resource allocation, strategic
            planning, and proactive support, empowering better decision-making
            at every level.
          </p>
        </div>
      </div>
      <div className="bg-gray-100" style={{ padding: "40px 0px" }}>
        <div className="container d-flex flex-column-reverse flex-lg-row align-items-start justify-content-between gap-5">
          <div className="col-lg-6">
            <h1 className="lh-1">Elevate Your Team’s Potential</h1>
            <p className="home-text mt-4">
              Our tool isn’t just about reporting; it’s about empowering every
              individual within your team. It encourages ownership,
              collaboration, and productivity, resulting in a more engaged and
              motivated workforce. With integrated timesheet management, team
              members can easily track their hours, promoting accountability and
              efficient time utilization.
            </p>
            <p className=" home-text mt-4">
              Start transforming the way your team reports, tracks time, and
              collaborates. Experience the power of streamlined reporting and
              enhanced collaboration—boost productivity, foster teamwork, and
              drive success.
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <img
              className="rounded-5 shadow"
              style={{
                width: "60%",
              }}
              src={bottom}
              alt="Paper"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
