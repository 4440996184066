import React, { useState, useRef } from "react";
import useClickOutside from "../utils/useClickOutside";

const FilterDropdown = (props) => {
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setShowFilterDropdown(false));
  let filterInputValue = props.value
    .filter((value) => value.isSelected)
    .map((value) => value.displayValue)
    .join(", ");

  return (
    <div
      className="d-flex flex-column position-relative"
      ref={dropdownRef}
    >
      <button
        className={`button filter-dropdown ${props.className}`}
        onClick={() =>
          setShowFilterDropdown(
            (prevShowFilterDropdown) => !prevShowFilterDropdown
          )
        }
      >
        <div className="border-0 cursor-pointer flex-grow-1 overflow-hidden">
          {filterInputValue || props.hintText}
        </div>
        {props.icon && <i className={`bi bi-${props.icon}`}></i>}
      </button>
      {showFilterDropdown && (
        <div
          className="filter-dropdown-list rounded border border-1"
          data-direction={props.direction}
        >
          <ul>
            {props.value.map((filterValue, i) => (
              <li key={i} className="form-check">
                <label className="form-check-label w-100 p-1 cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-check-input cursor-pointer"
                    checked={filterValue.isSelected}
                    onChange={(event) =>
                      props.handleFilterSelection(
                        event,
                        props.name,
                        filterValue.id
                      )
                    }
                  />
                  {filterValue.displayValue}
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
