import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreInfoButton from "./MoreInfoButton";
import RecipientsDialog from "./RecipientsDialog";
import DeleteUserDialog from "./DeleteUserDialog/DeleteUserDialog";
import { useApiCall } from "../middleware/useApiCall";
import { useAlert } from "../context/AlertContext";
import { deleteUsers } from "../api/apiCalls";

const User = function (props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {setDeleteUserDialog, ...propsForUpdateUser} =props;

  return (
    <tr className="user-info align-middle ">
      <th scope="row">{props.code.slice(-4)}</th>
      <td>{props.name}</td>
      <td>{props.email}</td>
      <td>{props.role}</td>
      <td>{props.manager.name}</td>
      <td className="d-flex flex-row align-items-center justify-content-center gap-2">
        <RecipientsDialog show={show} handleClose={handleClose} manager={props.manager} recipients={props.additional_recipients}/>
        {
          props.additional_recipients.length > 0 ? (
          <MoreInfoButton onClick={handleShow} />
        ) : (
          <button type="button" className="btn btn-light" disabled>
            <i className="bi bi-three-dots-vertical text-light"></i>
          </button>
        )}
        <Link className="button actions" to="update-user" state={propsForUpdateUser}>
          <i className="bi bi-pencil-fill"></i>
        </Link>
        <button className="task-delete-btn button" onClick={() => setDeleteUserDialog({show: true, selectedUser: { id: props.code, subordinates: props.subordinates }})}>
          <i className="bi bi-trash"></i>
        </button>
      </td>
    </tr>
  );
};
const UsersTable = (props) => {
  const { showSuccessAlert, showErrorAlert } = useAlert();
  const apiCallMiddleware = useApiCall();
  const [deleteUserDialog, setDeleteUserDialog] = useState({show: false, selectedUser: null});
  const handleCloseDeleteUserDialog = () => setDeleteUserDialog({show: false, selectedUser: null,});

  // function for deleting a user
  async function handleDeleteUser () {
    const data = await apiCallMiddleware(deleteUsers, [{id: deleteUserDialog.selectedUser?.id}]);
    if(data && data.count === 1){
      showSuccessAlert("User deleted successfully!");
    }else {
      showErrorAlert("User couldn't be deleted! Please try again later.");
    }
    // getting updated list of users
    await props.usersDataAndFilter();
    // closing the dialog
    handleCloseDeleteUserDialog();
  }
  
  // function to give updated user array when user types in filter input
  function userArray() {
    if (props.nameFilter) {
      return props.users.filter((user) =>
        user.name.toUpperCase().startsWith(props.nameFilter.toUpperCase())
      );
    }
    return props.users;
  }
  return (
    <>
      <DeleteUserDialog  deleteUserDialog={deleteUserDialog} handleClose={handleCloseDeleteUserDialog} handleDelete={handleDeleteUser} />
      <table className="table users-table">
        <thead>
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Name</th>
            <th scope="col">E-mail</th>
            <th scope="col">Role</th>
            <th scope="col">Manager</th>
            <th scope="col" className="text-center">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {userArray().map((user, i) => (
            <User key={i} {...user} userArray={props.users} setDeleteUserDialog={setDeleteUserDialog} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UsersTable;
