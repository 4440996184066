import React, { useState, useEffect, useCallback } from "react";
import MyReportsFilter from "../components/MyReportsFilter";
import {SubordinateReportCardList} from "../components/ReportCardList";
import { orderReports } from "../utils/orderReports";
import {
  firstAndLastDateOfMonth,
  dateInApiFormat,
} from "../utils/dateUtils";
import { useDispatch, useSelector } from "react-redux";
import { getSubordinateReports } from "../redux/subordinateReports/actions";
import { errorSelector, subordinateReportsSelector } from "../redux/subordinateReports/selector";
import { getSubordinates } from "../redux/subordinates/actions";
import { subordinatesSelector } from "../redux/subordinates/selector";
import { useAlert } from "../context/AlertContext";
import reportFormatter from "../formatters/reportFormatter";
import User from "../utils/userInfo";

/* function that takes in startDate , endDate and list of subordinates 
and returns the filtered list of reports according to startDate endDate and selected subordinates */
function filterUIData(reportUIData, startDate, endDate, subordinates) {
  let data = reportUIData;
  // filtering data according to selected subordinates
  if(subordinates instanceof Array) {
    const users = subordinates.filter(user => user.isSelected)
    if(users.length > 0){
      data = data.filter(data => users.some(a => a.id === data.user.id))
    }
  }
  // filtering data according to selected dates
  if(startDate){
    const filterStartDate = dateInApiFormat(startDate);
    data= data.filter(
      (data) => {
        const date = dateInApiFormat(data.date)
        return date >= filterStartDate
      }
    )
  }
  if (endDate){
    const filterEndDate = dateInApiFormat(endDate);
    data= data.filter(
      (data) => {
        const date = dateInApiFormat(data.date)
        return date <= filterEndDate
      }
    )
  }
  return data
}

const SubordinateReports = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const report_reducer = useSelector(subordinateReportsSelector);
  const subordinates_reducer = useSelector(subordinatesSelector);
  const { showErrorAlert } = useAlert();

  const [filteredReportsData, setFilteredReportsData] = useState(null);
  const [reportsDataUI, setReportUIData] = useState(null)
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({
    dateFilter: null,
    monthFilter: null,
    subordinates: [],
  });

  useEffect(() => {
    async function fetchSubordinateDetails() {
      try {
        await dispatch(
          getSubordinates({
            userId: User.getUserInfo().id,
            giveSubordinateWithNotificationEmail: true,
          })
        ).unwrap();
      } catch (error) {
        console.error(error);
        showErrorAlert(error.message);
      }
    }
    fetchSubordinateDetails();
  }, [dispatch, showErrorAlert]);

  useEffect(() => {
    if (error) {
      showErrorAlert(error);
    }
  }, [error, showErrorAlert]);

  useEffect(() => {
    if (subordinates_reducer) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        subordinates: subordinates_reducer.map((subordinate) => ({
          id: subordinate.id,
          displayValue: subordinate.name,
          isSelected: false,
        })),
      }));
      const users = subordinates_reducer.map((subordinate) => ({
        id: subordinate.id,
      }));
      dispatch(getSubordinateReports({ users }));
    }
  }, [subordinates_reducer, dispatch]);

  useEffect(() => {
    if (report_reducer) {
      // formatting reports for UI
      const formattedReports = report_reducer.map((report) =>
        reportFormatter(report)
      );
      setReportUIData(formattedReports);
    }
  }, [report_reducer]);

  // functionality for updating date and month filters
  function updateFilter(key, value) {
    setFilters((prevFilters) => ({
      ...prevFilters,
      dateFilter: null,
      monthFilter: null,
      [key]: value,
    }));
  }

  // functionality for updating subordinate filters
  function handleSubordinateFilterSelection(event, name, id) {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: prevFilters[name].map((value) =>
        value.id === id
          ? { ...value, isSelected: event.target.checked }
          : value
      ),
    }));
  }

  // functionality for clearing filters
  function clearFilter() {
    setFilters((prevFilters) => ({
      dateFilter: null,
      monthFilter: null,
      subordinates: prevFilters.subordinates.map((subordinate) => ({
        ...subordinate,
        isSelected: false,
      })),
    }));
    setShowFilter(false);
  }

  // function to check if any filter is applied
  const isAnyFilterSelected = useCallback(() => {
    if (
      filters.dateFilter ||
      filters.monthFilter ||
      filters.subordinates.some((subordinate) => subordinate.isSelected)
    ) {
      return true;
    }
    return false;
  }, [filters]);

  useEffect(() => {
    if (isAnyFilterSelected()) {
      // setting startDate and endDate according to the filters
      let startDate, endDate
      if (filters.monthFilter) {
        ({ firstDate: startDate, lastDate: endDate } = firstAndLastDateOfMonth(
          filters.monthFilter
          ));
          endDate = endDate > new Date() ? new Date() : endDate;
        } else if (filters.dateFilter) {
          startDate = new Date(filters.dateFilter);
          endDate = new Date(startDate);
        }
        // getting filtered data according to applied filters
        const filteredUI = filterUIData(reportsDataUI, startDate, endDate, filters.subordinates)
        setFilteredReportsData(filteredUI)
    } else {
      setFilteredReportsData(null);
    }
  }, [filters, isAnyFilterSelected]);

  return (
    <main id="main" className="main subordinate-reports">
      <div className="pagetitle d-flex align-items-center">
        <h1>Subordinate Reports</h1>
        <button
          type="button"
          className={`filter-btn button ms-auto ${
            isAnyFilterSelected() ? "filter-btn-active" : ""
          }`}
          onClick={() => setShowFilter((prevShowFilter) => !prevShowFilter)}
        >
          <i className="bi bi-funnel"></i>
        </button>
      </div>
      {showFilter && (
        <MyReportsFilter
          setShowFilter={setShowFilter}
          filters={filters}
          updateFilter={updateFilter}
          clearFilter={clearFilter}
          handleSubordinateFilterSelection={handleSubordinateFilterSelection}
        />
      )}
      {reportsDataUI && (reportsDataUI.length > 0 ? (
        <SubordinateReportCardList
          reportsData={
            filteredReportsData
              ? orderReports(filteredReportsData)
              : orderReports(reportsDataUI)
          }
        />
      ) : (
        <div className="fs-5">
          Either you don't have any subordinates or your subordinates haven't
          submitted any report.
        </div>
      ))}
    </main>
  );
};

export default SubordinateReports;
