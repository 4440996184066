import { createAsyncThunk } from "@reduxjs/toolkit";
import { getTimeSheet } from "../../api/apiCalls";
import timeSheetFormatter from "../../formatters/timeSheetFormatter";
import User from "../../utils/userInfo";

export const getTimeSheetAction = createAsyncThunk(
  "timeSheet/getTimeSheet",
  async (requestObject) => {
    const responseData = await getTimeSheet(requestObject);
    const userInfo = User.getUserInfo();

    // formatting time sheet and adding self information
    return responseData.map((userTimeSheetData) => ({
      ...timeSheetFormatter(userTimeSheetData),
      user: { id: userInfo.id, name: userInfo.name },
    }));
  }
);

export const getSubordinateTimeSheetAction = createAsyncThunk(
  "timeSheet/getSubordinateTimeSheet",
  async (requestObject, thunkApi) => {
    const state = thunkApi.getState();
    const subordinates = state && state.subordinates;

    const responseData = await getTimeSheet(requestObject);

    // formatting time sheet and adding subordinates information
    return responseData.map((userTimeSheetData) => {
      const subordinate = subordinates.find(
        (subordinate) => subordinate.id === userTimeSheetData.user_id
      );
      return {
        ...timeSheetFormatter(userTimeSheetData),
        user: { id: subordinate.id, name: subordinate.name },
      };
    });
  }
);
