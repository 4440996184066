import React from "react";

const UserMenu = ({ userInfo, userMenuOptions }) => {
  return (
    <ul className="dropdown-list dropdown-list-arrow position-absolute profile postion-absolute end-0 me-2">
      <li className="dropdown-header">
        <h6>{userInfo.name}</h6>
        <span>{userInfo.designation}</span>
      </li>
      {userMenuOptions.map((userMenuOption) => (
        <li key={userMenuOption.place}>
          <hr />
          <button
            className="dropdown-unit d-flex align-items-center"
            onClick={userMenuOption.onClick}
          >
            <i className={`bi bi-${userMenuOption.icon}`}></i>
            <span>{userMenuOption.name}</span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default UserMenu;
