import React, { useEffect, useRef, useState } from "react";
import UserMenu from "./UserMenu";
import User from "../utils/userInfo";
import useClickOutside from "../utils/useClickOutside";
import { useKeycloak } from "@react-keycloak/web";
import { Link } from "react-router-dom";

const UserOptions = ({helpAction, showUserIcon}) => {
  const { keycloak } = useKeycloak();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const dropdownRef = useRef(null);
  useClickOutside(dropdownRef, () => setShowUserMenu(false));
  useEffect(() => {
    const { picture: imgURL, ...user } = User.getUserInfo();
    setUserInfo({ ...user, imgURL });
  }, []);

  const userMenuOptions = [
    // {
    //   place: 1,
    //   name: "My Profile",
    //   onClick() {
    //     console.log(`Profile option clicked`);
    //   },
    //   icon: "person",
    // },
    // {
    //   place: 2,
    //   name: "Account Settings",
    //   onClick() {
    //     console.log(`Account Settings option clicked`);
    //   },
    //   icon: "gear",
    // },
    {
      place: 3,
      name: "Help",
      onClick() {
        window.open(process.env.REACT_APP_PODIT_CONTACT_US_URL, "_blank");
      },
      icon: "question-circle",
    },
    {
      place: 4,
      name: "Sign Out",
      onClick() {
        return keycloak.logout({redirectUri: window.location.origin});
      },
      icon: "box-arrow-right",
    },
  ];

  return (
    <nav className="header-nav ms-auto d-flex align-items-center gap-3">
      {keycloak.authenticated ? (
        <>
          <i
            className="bi bi-question-circle tour-btn me-2 "
            role="button"
            onClick={helpAction}
          ></i>
          {
            showUserIcon && <div
            role="button"
            className="d-flex flex-column nav-item pe-3 position-relative"
            ref={dropdownRef}
            onClick={(e) => {
              setShowUserMenu((prevShowUserMenu) => !prevShowUserMenu);
            }}
          >
            <div className="nav-link nav-profile d-flex align-items-center pe-0">
              {userInfo.imgURL ? (
                <img src={userInfo.imgURL} alt="" className="rounded-circle" />
              ) : (
                <span className="profile-picture">
                  {userInfo.name?.slice(0, 1).toUpperCase()}
                </span>
              )}
              <i
                className="bi bi-caret-down-fill m-1"
                style={{ fontSize: "0.8rem" }}
              ></i>
            </div>
            {showUserMenu && (
              <UserMenu userInfo={userInfo} userMenuOptions={userMenuOptions} />
            )}
          </div>
        }
        </>
      ) : (
        <div className="d-flex gap-5 align-items-center justify-content-between px-5 public-nav">
          <Link to="/" className="fs-6 fw-bold ">
            Home
          </Link>
          <Link to="/license" className="fs-6 fw-bold active">
            License
          </Link>
          <button
            id="submit-btn"
            className="btn px-3 py-1 rounded"
            onClick={() =>
              keycloak.register()
            }
          >
            Try Now
          </button>
          <Link className="btn fs-6 fw-bold" onClick={() => keycloak.login({redirectUri : window.location.origin + "/dashboard"})}>
            Sign In
          </Link>
        </div>
      )}
    </nav>
  );
};

const Header = ({publicHeader, helpAction, showUserIcon}) => {
  function toggleSidebar() {
    const body = document.body;
    body.classList.toggle("toggle-sidebar");
  }

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className={`${publicHeader ? "public-logo" : "logo"} d-flex align-items-center justify-content-between`}>
        <div className="d-flex align-items-center">
          <div className="logo-img"/>
        </div>
          <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
      </div>
      <UserOptions helpAction={helpAction} showUserIcon={showUserIcon}/>
    </header>
  );
};

export default Header;
