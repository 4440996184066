import { getTaskStatusForUI } from "../constant/statusMap";
import { isSameDay } from "../utils/dateUtils";
import { commentFormatter } from "./taskCommentFormatter";

/**
 * This function takes in a list of reports, a start date, and an end date, and returns a list of formatted reports.
 * The function uses the isSameDay function to determine if two dates represent the same day.
 * The function uses the getTaskStatusForUI function to get the status of a task for display purposes.
 * @param {Date} startDate - The start date of the period to retrieve reports for
 * @param {Date} endDate - The end date of the period to retrieve reports for
 * @param {object[]} reports - The list of reports to process
 * @returns {object[]} The processed list of reports
 */
export function apiReportDetailsToUI(startDate, endDate, reports) {
  let reportsDataForUI = [];
  let finalResult = [];

  for (let report of reports) {
    const tasks = [...report.todayTasks, ...report.tomorrowTasks]
    const newReport = {
      reportId: report.id,
      date: report.reportDate,
      submitted: true,
      postedOn: report.createdDate,
      sentTo: report.user.notification_email,
      linkedTasks: [
        {
          date: "Tasks",
          tasks: tasks?.map((task) => ({
            name: task.title,
            description: commentFormatter(task.comment),
            status: getTaskStatusForUI(task.status),
          })),
        },
        // {
        //   date: "Tomorrow's Task",
        //   tasks: report.tomorrowTasks?.map((task) => ({
        //     name: task.title,
        //     description: commentFormatter(task.comment),
        //     status: getTaskStatusForUI(task.status),
        //   })),
        // },
      ],
    };
    reportsDataForUI.push(newReport);
  }

  // Setting the end date to last millisecond of the day
  endDate.setHours(23, 59, 59, 999);


  /**
   *Looping through each day and checking if the date and report is same
   *then return the report if not then update the date and set submitted to false
  */
  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
      let report = reportsDataForUI.find((report) => isSameDay(date, report.date));
      let result = report
        ? report
        : { date: new Date(date), submitted: false };
      finalResult.push(result);
  }
  return finalResult;
}