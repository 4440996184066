import React from "react";

/**
 * Renders a list of recipients
 * @param {object[]} recipients - an array of recipient objects
 * @returns {JSX.Element} a list of recipients
 */
function RecipientList({ recipients }) {
  return (
    <div>
      {recipients.length > 0 && (
        <ul className="list-group list-group-flush list-group-numbered">
          {recipients.map((recipient) => {
            return (
              <li className="text-start list-group-item px-0 " key={recipient?.id}>
                {recipient?.name}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default RecipientList;
