import React, { createContext, useContext, useState, useCallback } from "react";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ isVisible: false, message: "" });

  const showErrorAlert = useCallback((message) => {
    setAlert({ error: true, message, isVisible: true });
    setTimeout(
      () => setAlert({ message: "", isVisible: false }),
      3000
    );
  }, []);

  const showSuccessAlert = (message) => {
    setAlert({ error: false, message, isVisible: true });
    setTimeout(
      () => setAlert({ message: "", isVisible: false }),
      3000
    );
  };

  return (
    <AlertContext.Provider value={{ alert, showErrorAlert, showSuccessAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
