import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

const PublicNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const navItems = [
    {
      navPlace: 1,
      name: "Home",
      onClick() {
        navigate("/");
      },
    },
    {
      navPlace: 2,
      name: "License",
      onClick() {
        navigate("/license");
      },
    },
    {
      navPlace: 3,
      name: "Try Now",
      onClick() {
        keycloak.register();
      },
    },
    {
      navPlace: 4,
      name: "Sign In",
      onClick() {
        keycloak.login({redirectUri: window.location.origin + "/dashboard"});
      },
    },
  ];

  useEffect(() => {
    const isSmallScreen = window.innerWidth <= 1200;

    if (isSmallScreen) {
      document.body.classList.remove("toggle-sidebar");
    }
  }, [location]);

  return (
    <aside id="public-sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {navItems.map((navItem) => (
          <li key={navItem.navPlace} className="nav-item" onClick={navItem.onClick}>
            <button className="nav-link collapsed" style={{ width: "100%" }}>
              <span>{navItem.name}</span>
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default PublicNavbar;
