export default class User {

   static userInfo = {}


  static setUserInfo( user) {
    User.userInfo = {...User.userInfo, ...user}
  }

  static setKeycloakToken(token){
    User.userInfo.keycloakToken = token
  }

  static getKeycloakToken(){
    if(User.userInfo.keycloakToken) return User.userInfo.keycloakToken
    throw new Error("Keycloak Token not found!");
  }

  static setToken(token) {
    User.userInfo.token = token;
  }

  static getToken() {
    if(User.userInfo.token){
      return User.userInfo.token;
    }
    throw new Error("Token not found!");
  }

  static getUserInfo() {
    return User.userInfo;
    // return {
    //   id: "65aa51446c25779d21c34657",
    // };
  }
}
