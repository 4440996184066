import React, { useState, useEffect, useCallback } from "react";
import MyReportsFilter from "../components/MyReportsFilter";
import TimeSheetTable from "./TimeSheetTable/TimeSheetTable";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "../context/AlertContext";
import { dateInApiFormat, firstAndLastDateOfMonth, getMonthFromDate } from "../utils/dateUtils";
import { getTimeSheet } from "../api/apiCalls";
import downloadFileFromBlob from "../utils/downloadFileFromBlob";

const TimeSheetPage = ({ title, dataFetchAction, dataSelector, userIds }) => {
  const dispatch = useDispatch();
  const { showErrorAlert } = useAlert();
  const timeSheetData = useSelector(dataSelector);

  const [filters, setFilters] = useState({ monthFilter: new Date() });
  const [showFilters, setShowFilters] = useState(false);

  let { firstDate, lastDate } = firstAndLastDateOfMonth(filters.monthFilter);
  lastDate = lastDate > new Date() ? new Date() : lastDate;

  const month = getMonthFromDate(filters.monthFilter);

  const fetchTimeSheetData = useCallback(async () => {
    try {
      await dispatch(
        dataFetchAction({
          startDate: dateInApiFormat(firstDate),
          endDate: dateInApiFormat(lastDate),
          userIds: userIds,
          returnType: "json",
        })
      ).unwrap();
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }, [dispatch, dataFetchAction, filters.monthFilter, showErrorAlert, userIds]);

  // useEffect that runs on changing of filter.monthFilter
  useEffect(() => {
    fetchTimeSheetData();
  }, [fetchTimeSheetData]);

  function clearFilter() {
    setFilters({ monthFilter: new Date() });
    setShowFilters(false);
  }

  function updateFilter(name, value) {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  }

  async function downloadTimeSheet(fileType) {
    try {
      const fileBlob = await getTimeSheet({
        startDate: dateInApiFormat(firstDate),
        endDate: dateInApiFormat(lastDate),
        userIds: userIds,
        returnType: fileType,
      })

      const month = filters.monthFilter.getMonth() + 1;
      const year = filters.monthFilter.getFullYear();
      const fileName = `${month}-${year}.${fileType === "pdf"? "pdf" : "xlsx"}`
      downloadFileFromBlob(fileBlob, fileName);
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }
  return (
    <main id="main" className="main my-time-sheet overflow-x-auto">
      <div className="pagetitle d-flex justify-content-between">
        <h1>{title}</h1>
        <button
          type="button"
          className={`filter-btn button ms-auto ${
            Object.values(filters).some((v) => !!v) ? "filter-btn-active" : ""
          }`}
          onClick={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
        >
          <i className="bi bi-funnel"></i>
        </button>
      </div>
      {showFilters && (
        <MyReportsFilter
          filters={filters}
          updateFilter={updateFilter}
          clearFilter={clearFilter}
        />
      )}
      <TimeSheetTable
        startDate={firstDate}
        endDate={lastDate}
        month={month}
        timeSheetData={timeSheetData}
        downloadTimeSheet={downloadTimeSheet}
      />
    </main>
  );
};

export default TimeSheetPage;
