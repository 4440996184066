import { useEffect } from "react";
export default function useClickOutside(ref, onClickOutside) {
    useEffect(() => {
      function handleClickOutside(event) {
        // running the function only when clicked outside of element
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      }
      // event handler for click on anywhere in document
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // cleanup code
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }