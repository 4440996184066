import "./deleteUserDialog.css";
import React from "react";
import Modal from "react-bootstrap/Modal";

const DeleteUserDialog = ({ deleteUserDialog, handleClose, handleDelete }) => {
  return (
    <Modal
      centered
      show={deleteUserDialog.show}
      onHide={handleClose}
      dialogClassName="delete-dialog position-relative"
    >
      <div className="d-flex flex-row justify-content-center py-3">
        <h4 className="fw-bold">Delete User ?</h4>
        <button
          className="filter-close-btn button px-3 position-absolute end-0"
          onClick={handleClose}
        >
          <i className="bi bi-x"></i>
        </button>
      </div>
      <div className="dialog-body d-flex flex-column gap-1 mt-4">
        <span className="text-center">
          Are you sure you want to delete this user?
        </span>
        <span className="text-center">You can't undo this action.</span>
        {deleteUserDialog.selectedUser && deleteUserDialog.selectedUser.subordinates.length > 0 && (
          <div className="delete-warning text-danger d-flex flex-row gap-2 mt-3 p-2">
            <i className="bi bi-exclamation-triangle-fill ps-2"></i>

            <span>
              By deleting this user, {deleteUserDialog.selectedUser.subordinates.length}{" "}
              subordinates needed to assign to other user.
            </span>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-evenly py-3">
        <button
          id="secondary-btn"
          className="btn px-5 py-2 mt-2 mb-3 rounded"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          id="delete-btn"
          className="btn px-5 py-2 mt-2 mb-3 rounded"
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
};

export default DeleteUserDialog;
