import React, { useEffect, useMemo } from "react";
import TimeSheetPage from "../components/TimeSheetPage";
import { getSubordinateTimeSheetAction } from "../redux/timeSheet/actions";
import { subordinateTimeSheetSelector } from "../redux/timeSheet/selector";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "../context/AlertContext";
import { getSubordinates } from "../redux/subordinates/actions";
import { subordinatesSelector } from "../redux/subordinates/selector";
import User from "../utils/userInfo";

const SubordinateTimeSheet = () => {
  const { showErrorAlert } = useAlert();
  const dispatch = useDispatch();
  const subordinates_reducer = useSelector(subordinatesSelector);

  const subordinateUserIds = useMemo(() =>subordinates_reducer.map(
    (subordinate) => subordinate.id
  ), [subordinates_reducer]);

  useEffect(() => {
    async function fetchSubordinateDetails() {
      try {
        await dispatch(
          getSubordinates({
            userId: User.getUserInfo().id,
            giveSubordinateWithNotificationEmail: true,
          })
        ).unwrap();
      } catch (error) {
        console.error(error);
        showErrorAlert(error.message);
      }
    }
    fetchSubordinateDetails();
  }, [dispatch, showErrorAlert]);

  return (
    <TimeSheetPage
      title="Subordinate Timesheet"
      dataFetchAction={getSubordinateTimeSheetAction}
      dataSelector={subordinateTimeSheetSelector}
      userIds={subordinateUserIds}
    />
  );
};

export default SubordinateTimeSheet;
