import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSubordinates as getSubordinatesCall } from "../../api/apiCalls";

export const getSubordinates = createAsyncThunk(
  "subordinates/getSubordinates",
  async (filterObject) => {
      const response = await getSubordinatesCall(filterObject);
      return response;
  }
);
