import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReportsStats, getAdminStatsCall } from "../../api/apiCalls";

export const getReportStats = createAsyncThunk(
  "stats/getReportStats",
  async (data, thunkApi) => {
    const { startDate, endDate, userId } = data;
    try {
      const response = await getReportsStats(startDate, endDate, userId);
      return { data: response };
    } catch (e) {
      return thunkApi.rejectWithValue(e.message)
    }
  }
);

export const getAdminStats = createAsyncThunk(
  "stats/getAdminStats",
  async (data, thunkApi) => {
    const { startDate, endDate, userId } = data;
    try {
      const response = await getAdminStatsCall(startDate, endDate, userId);
      return { data: response };
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
