import React, { useState } from "react";
import Answer from "./Faq/Answer";

function Faq() {
  const [selected, setSelected] = useState(null);

  function toggle(i) {
    if (selected === i) {
      setSelected(null);
    } else {
      setSelected(i);
    }
  }

  return (
    <div className="faq-container">
      <div className="faq-list">
        {faqList.map((item, i) => (
          <div key={i} className="faq-item">
            <div
              className={selected === i ? "title-active" : "title"}
              onClick={() => toggle(i)}
            >
              <h4>{item.question}</h4>
              <i
                className={
                  selected === i
                    ? "bi bi-dash-lg text-light fs-5 fw-bold "
                    : "bi bi-plus-lg fw-bold fs-5 "
                }
              ></i>
            </div>
            <div className={selected === i ? "content-show" : "content"}>
              <Answer text={item.answer} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;

const faqList = [
  {
    question: "What is Task Report service?",
    answer: [
      "A task report is a concise summary detailing the progress, status, and completion of specific assignments or activities within a project or team. It provides a snapshot of tasks completed, in progress, and upcoming, helping to track productivity and inform decision-making.",
    ],
  },
  {
    question: "What is the organization during registration?",
    answer: [
      "This will be your organisation name or a group you want to define for your team to run task report service.",
    ],
  },
  {
    question: "How many users I can create?",
    answer: ["You can create as many users as you like."],
  },
  {
    question: "Who is manager?",
    answer: [
      "Manager is the reporting person who will monitor the user's tasks and performance.",
    ],
  },
  {
    question: "How should I add multiple recipients for the same user?",
    answer: [
      "First of all, in order to perform update user action you have to be admin. Go to the update user page and add multiple recipients in additional recipients drop down",
    ],
  },
  {
    question: "What are different status in task report?",
    answer: [
      "Task status reflect various stages of progress:",
      "Open: Any new task created will be in this state till the time any work is started.",
      "In Progress: A task that you are working on will always keep this state.",
      "Help Required: If the task require help from other stake holders and you want to notify this to your manager and additional participants.",
      "On Hold: For some reason task is paused for some time but will be picked up again for work.",
      "Closed: Any task that does not require any further work.",
      "Above statuses streamline communication and guide our workflow effectively."
    ],
  },
];
