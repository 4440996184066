/**
 * This function takes blob of file and file name and downloads the file
 * @param {object} fileBlob - The blob object of file
 * @param {string} fileName - file name for download file
 */

export default function downloadFileFromBlob(fileBlob, fileName) {
  const fileUrl = window.URL.createObjectURL(fileBlob);

  // Create a temporary <a> element to trigger the download
  const tempLink = document.createElement("a");
  tempLink.href = fileUrl;
  tempLink.setAttribute("download", fileName);

  // Append the <a> element to the body and click it to trigger the download
  document.body.appendChild(tempLink);
  tempLink.click();

  // Clean up the temporary elements and URL
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(fileUrl);
}
