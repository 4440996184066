import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import MyReportsFilter from "../components/MyReportsFilter";
import ReportCardList from "../components/ReportCardList";
import { orderReports } from "../utils/orderReports";
import { useDispatch, useSelector } from 'react-redux';
import User from "../utils/userInfo";
import { getReports } from "../redux/reports/actions";
import { reportsSelector, errorSelector } from "../redux/reports/selector";
import { useAlert } from "../context/AlertContext";
import { firstAndLastDateOfMonth } from "../utils/dateUtils";
import { apiReportDetailsToUI } from "../formatters/apiReportDetailsToUI";

const MyReports = () => {
  // getting date from dashboard calendar
  const location = useLocation();
  const dateFromDashboardCalendar = location.state?.dateFromDashboardCalendar;

  const user = User.getUserInfo();
  const { showErrorAlert } = useAlert();

  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState({dateFilter:dateFromDashboardCalendar, monthFilter:null});
  const dispatch = useDispatch();
  let error = useSelector(errorSelector);
  let report_reducer = useSelector(reportsSelector)
  const [ formattedReports, setFormattedReports ] = useState([]);
  
  useEffect(() => {
    let endDate = new Date();
    let requestBody = {
      startDate: new Date(user.createdDate),
      endDate: endDate,
      userId: user.id,
    };
    dispatch(getReports(requestBody))
  }, [dispatch]);

  useEffect(()=>{
    if(error) {
      showErrorAlert(error)
    }
  },[error])

  useEffect(()=>{
    if(report_reducer){
      const userCreationDate = new Date(User.getUserInfo().createdDate);
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - 1);
      // setting startDate equal to user creation date when startDate is greater than user creation date
      startDate = startDate < userCreationDate ? userCreationDate : startDate; 
      let endDate = new Date();
      if(filters.dateFilter) {
        startDate = new Date(filters.dateFilter);
        endDate = new Date(filters.dateFilter);
      } 
      if(filters.monthFilter) {
        ({ firstDate: startDate, lastDate: endDate } = firstAndLastDateOfMonth(filters.monthFilter));
        startDate = startDate < userCreationDate ? userCreationDate : startDate; 
        endDate = endDate > new Date() ? new Date() : endDate;
      }
      let updatedReports = apiReportDetailsToUI(startDate, endDate, report_reducer)
      setFormattedReports(updatedReports);
    }
  }, [filters, report_reducer]) 

  function updateFilter(key, value){
    setFilters({dateFilter:null, monthFilter:null, [key]: value});
  }

  function clearFilter(){
    setFilters({dateFilter:null, monthFilter: null});
    setShowFilter(false);
  }

  return (
    <main id="main" className="main my-reports">
      <div className="pagetitle d-flex align-items-center">
        <h1>My Reports</h1>
        <button
          type="button"
          className={`filter-btn button ms-auto ${
            Object.values(filters).some(v=>!!v) ? "filter-btn-active" : ""
          }`}
          onClick={() => setShowFilter((prevShowFilter) => !prevShowFilter)}
        >
          <i className="bi bi-funnel"></i>
        </button>
      </div>
      {showFilter && <MyReportsFilter setShowFilter={setShowFilter} filters={filters} updateFilter={updateFilter} clearFilter={clearFilter} minDate={new Date(user.createdDate)}/>}
      <ReportCardList reportsData={ orderReports(formattedReports) } />
    </main>
  );
};

export default MyReports;
