import React from "react";
import FilterDropdown from "./FilterDropdown";
const UserFilter = (props) => {
  return (
    <div className="filter d-flex align-items-center justify-content-between gap-2 p-1">
      <div className="filter-div d-flex flex-row align-items-center gap-1 px-1">
        <i className="bi bi-filter" style={{fontSize: "20px"}}></i>
        <input
          type="text"
          className="filter-input border-0 ms-1"
          placeholder="Filter by name"
          value={props.nameFilter}
          onChange={(event) => props.setNameFilter(event.target.value)}
          autoFocus
        />
      </div>
      <div className="d-flex align-items-center gap-2">
        {props.userFilter.map((filter, i) => (
          <FilterDropdown
          key={i}
          {...filter}
          direction="down"
          icon="caret-down-fill"
          handleFilterSelection={props.handleFilterSelection}
          className="restrict-width border border-2 p-1 w-100 d-flex flex-row"
        />
        ))}
        <button
          type="button"
          className="filter-close-btn button"
          onClick={props.clearFilter}
        >
          <i className="bi bi-x"></i>
        </button>
      </div>
    </div>
  );
};

export default UserFilter;
