import { createSlice } from "@reduxjs/toolkit";
import { getReports } from "./actions";

const initialState = {
  error: null,
  reports: [],
};

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.error = null;
      state.reports = action.payload.data;
    });
    builder.addCase(getReports.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default reportsSlice.reducer;
