import React, { useEffect } from "react";
import Card from "../components/Card";
import DashboardCalendar from "../components/DashboardCalendar";
import User from "../utils/userInfo";
import { useDispatch, useSelector } from "react-redux";
import { getAdminStats, getReportStats } from "../redux/stats/actions";
import { statsSelector } from "../redux/stats/selector";
import { useAlert } from "../context/AlertContext";

const Dashboard = () => {
  const user = User.getUserInfo();
  const isAdmin = user.role === "admin";
  const { showErrorAlert } = useAlert();
  const dispatch = useDispatch();
  const { reportStats, error } = useSelector(statsSelector);

  useEffect(() => {
    let startDate = new Date(user.createdDate);
    let endDate = new Date();
    let requestBody = {
      startDate: startDate,
      endDate: endDate,
      userId: user.id,
    };
    isAdmin
      ? dispatch(getAdminStats(requestBody))
      : dispatch(getReportStats(requestBody));
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showErrorAlert(error);
    }
  }, [error]);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
      </div>
      <section className="section dashboard">
        <div className="row justify-content-md-center align-items-start">
          <div className="col-xxl-6 col-xl-12 col-md-6">
            <div className="row">
              <div id="total-reports" className="col-xxl-6 col-xl-4 col-md-6">
                <Card
                  heading={"Total Reports"}
                  icon={<i class="bi bi-file-earmark-text-fill"></i>}
                  style={{ backgroundColor: "var(--clr-secondary)", color: "var(--clr-highlight)" }}
                  value={reportStats.ExpectedReports}
                />
              </div>
              <div id="pending-reports" className="col-xxl-6 col-xl-4 col-md-6">
                <Card
                  heading={"Pending Reports"}
                  icon={<i class="bi bi-file-earmark-text-fill"></i>}
                  style={{ backgroundColor: "var(--clr-secondary)", color: "var(--clr-highlight)" }}
                  value={
                    reportStats.ExpectedReports -
                    reportStats.NumberOfSubmittedReports
                  }
                />
              </div>
              {isAdmin && (
                <div className="col-xxl-6 col-xl-4 col-md-6">
                  <Card
                    heading={"Users"}
                    icon={<i class="bi bi-person-circle"></i>}
                    style={{ backgroundColor: "var(--clr-error-alert-bg)", color: "var(--btn-danger-bg)" }}
                    value={reportStats.TotalUsers}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-xxl-6 col-xl-12 col-md-6">
            <DashboardCalendar
              submittedDates={reportStats.ReportSubmittedDates}
              userId={user.id}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Dashboard;
