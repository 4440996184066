import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserForm from "../components/UserForm";
import { useAlert } from "../context/AlertContext";
import { putUser } from "../api/apiCalls";
import { useApiCall } from "../middleware/useApiCall";

const UpdateUser = () => {
  const {showErrorAlert, showSuccessAlert} = useAlert();
  const location = useLocation();
  const props = location.state;
  const activeUsers = props && props.userArray; 
  const navigate = useNavigate();
  const apiCallMiddleware = useApiCall();

  const [formState, setFormState] = useState({
    code: props.code,
    name: props.name,
    email: props.email,
    role: props.role,
    manager: props.manager.id,
    recepients: activeUsers
      .filter(user => user.id !== props?.id)
      .map((user) => ({
      id: user.code,
      displayValue: user.name,
      isSelected: props.notification_email.includes(user.code),
    })),
  });

  function shouldDisableSubmitButton() {
    return formState.name === "" || formState.email === "";
  }

  function handleInputChange(event) {
    setFormState((prevFormState) => {
      if (event.target.type === "checkbox") {
        return { ...prevFormState, [event.target.name]: event.target.checked };
      }
      return {
        ...prevFormState,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleOptionSelection(event, name, id) {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: prevFormState[name].map((value) =>
        value.id === id ? { ...value, isSelected: event.target.checked } : value
      ),
    }));
  }

  async function handleFormSubmission() {
    try {
      const result = await apiCallMiddleware(putUser, formState);
      if(result instanceof Object){
        showSuccessAlert("User updated successfully!");
        navigate("/dashboard/users");
      }else {
        showErrorAlert("User couldn't be updated! Please try again later.");
      }
    } catch (error) {
      showErrorAlert(error.message);
    }
  }

  function renderContent() {
    if (props) {
      return (
        <div className="card col-sm-12  col-md-8 col-xl-6">
          <div className="card-body">
            <UserForm 
              {...props}
              createUser={false}
              formState={formState}
              shouldDisableSubmitButton={shouldDisableSubmitButton}
              handleInputChange={handleInputChange}
              handleOptionSelection={handleOptionSelection}
              handleFormSubmission={handleFormSubmission}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <main id="main" className="main">
      <div className="pagetitle d-flex justify-content-between">
        <h1>Update User</h1>
      </div>
      <div className="container row justify-content-center">
        {renderContent()}
      </div>
    </main>
  );
};

export default UpdateUser;
