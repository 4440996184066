import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTour } from "@reactour/tour";
import { getTourSteps } from "../constant/tourSteps";

const TourComponent = () => {
  const location = useLocation();
  const { isOpen, setSteps, setCurrentStep } = useTour();

  // getting steps that have selectors present in dom.
  function filteringSteps(steps) {
    const filteredSteps = steps.filter((step) => {
      if (step.selector === null) {
        return true;
      } else {
        const element = document.querySelector(step.selector);
        return element !== null;
      }
    });
    return filteredSteps;
  }

  useEffect(() => {
    // Get the base steps for the current location
    let baseSteps = getTourSteps(location.pathname);
    setSteps(baseSteps);
    setCurrentStep(0);
  }, [location, setSteps, setCurrentStep]);

  useEffect(() => {
    isOpen && setSteps((baseSteps) => filteringSteps(baseSteps));
  }, [isOpen, setSteps]);

  return;
};

export default TourComponent;
