import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import User from "../utils/userInfo";

const SimpleNavItem = function (props) {
  return (
    <li className="nav-item" id={props.id}>
      <Link
        to={props.path}
        className={`nav-link ${
          props.location.pathname === props.path ? "" : "collapsed"
        }`}
      >
        <i className={`bi bi-${props.icon}`}></i>
        <span>{props.name}</span>
      </Link>
    </li>
  );
};

const ExpandableNavItem = function (props) {
  const [showDropMenu, setShowDropMenu] = useState(false);
  useEffect(() => {
    const pathName = props.location.pathname;
    if (props.subItems.some((subItem) => pathName.includes(subItem.path))) {
      setShowDropMenu(true);
    } else {
      setShowDropMenu(false);
    }
  }, [props.location, props.subItems]);
  return (
    <li className="nav-item" id={props.id}>
      <div
        className={`nav-link ${showDropMenu ? "" : "collapsed"}`}
        onClick={() => setShowDropMenu((prevShowDropMenu) => !prevShowDropMenu)}
      >
        <i className={`bi bi-${props.icon}`}></i>
        <span>{props.name}</span>
        <i className="bi bi-chevron-down ms-auto"></i>
      </div>
      {showDropMenu && props.subItems && (
        <ul id="components-nav" className="nav-content collapsed">
          {props.subItems.map((item, i) => (
            <ExpandedSubNavItem key={i} {...item} location={props.location} />
          ))}
        </ul>
      )}
    </li>
  );
};

const ExpandedSubNavItem = function (props) {
  return (
    <li>
      <Link
        to={props.path}
        className={props.location.pathname === props.path || props.additionalPaths?.includes(props.location.pathname) ? "active" : ""}
      >
        <i className={`bi bi-${props.icon}`}></i>
        <span>{props.name}</span>
      </Link>
    </li>
  );
};

const Navbar = () => {
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);
  const adminNavItem = {
    navPlace: 6,
    name: "Admin",
    icon: "person-gear",
    id: "admin",
    subItems: [
      {
        name: "Users",
        path: "/dashboard/users",
        additionalPaths: ["/dashboard/users/update-user"],
        icon: "circle",
      },
    ],
  };
  const navItems = [
    {
      navPlace: 1,
      name: "Dashboard",
      path: "/dashboard",
      icon: "grid",
      id: "dashboard"
    },
    {
      navPlace: 2,
      name: "My Reports",
      path: "/dashboard/my-reports",
      additionalPaths:["/dashboard/my-reports/report-details"],
      icon: "journal",
      id: "my-reports"
    },
    {
      navPlace: 3,
      name: "Subordinate Reports",
      path: "/dashboard/subordinate-reports",
      icon: "journals",
      id: "subordinate-reports",
    },
    {
      navPlace: 4,
      name: "My Timesheet",
      path: "/dashboard/my-timesheet",
      icon: "file-earmark-spreadsheet",
      id: "subordinate-reports",
    },
    {
      navPlace: 5,
      name: "Subordinate Timesheet",
      path: "/dashboard/subordinate-timesheet",
      icon: "file-earmark-spreadsheet",
      id: "subordinate-reports",
    },
  ];

  useEffect(()=>{
    const user = User.getUserInfo();
    setIsAdmin( user.role?.toLowerCase() === "admin"  ?? false);
  }, [])

  useEffect(() => {
    const isSmallScreen = window.innerWidth <= 1200;

    if(isSmallScreen){
      document.body.classList.remove("toggle-sidebar");
    }
  }, [location])

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {navItems.map((navItem) => (
          <SimpleNavItem
            key={navItem.navPlace}
            {...navItem}
            location={location}
          />
        ))}
        {isAdmin && <ExpandableNavItem {...adminNavItem} location={location} />}
      </ul>
    </aside>
  );
};

export default Navbar;
