import React from "react";
import { useNavigate } from "react-router";
import Calendar from "react-calendar";
import User from "../utils/userInfo";

const DashboardCalendar = ({ submittedDates }) => {
  const navigate = useNavigate();

  function isSameDay(a, b) {
    let firstDate = new Date(a);
    let secondDate = new Date(b);
    return (
      Math.abs(firstDate.getTime() - secondDate.getTime()) < 86400000 &&
      firstDate.getDate() === secondDate.getDate()
    );
  }

  // function to add a classs name to dates on which user has successfully submitted the reports
  function tileClassName({ date, view }) {
    // Add class to tiles in month view only
    if (view === "month") {
      // Check if a date React-Calendar wants to check is on the list of dates when user has submitted the report
      if (
        submittedDates.find((dDate) => isSameDay(dDate, date))
      ) {
        return "react-calendar__tile--report-submitted";
      }
    }
  }

  // functionality for when user selects the date from calendar
  function handleDateSelection(dateFromDashboardCalendar) {
    navigate("my-reports", { state: { dateFromDashboardCalendar } });
  }

  return (
    <Calendar
      className="home-calendar"
      onChange={handleDateSelection}
      next2Label={null}
      nextLabel={null}
      prev2Label={null}
      prevLabel={null}
      tileClassName={tileClassName}
      value={new Date()}
      minDate={new Date(User.getUserInfo().createdDate)}
      maxDate={new Date()}
    />
  );
};

export default DashboardCalendar;
