import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import RecipientList from "./RecipientList";

function RecipientsDialog({ show, handleClose, manager, recipients }) {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      show={show}
      onHide={handleClose}
    >
      <Modal.Header className="py-1" closeButton>
        <Modal.Title className="ms-auto">Recipients</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {recipients.length > 0 && recipients ? (
          <>
            <RecipientList recipients={recipients} />
          </>
        ) : (
          <p>No recipients for this user</p>
        )}
      </Modal.Body>
      <Modal.Footer className="flex-column py-1">
        <Button
          className="align-items-middle"
          variant="primary"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RecipientsDialog;
