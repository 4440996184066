import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PublicNavbar from "../components/PublicNavbar";
import RefreshScreen from "./RefreshPage/RefreshScreen";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

const PublicLayout = () => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  if (initialized) {
    if (keycloak.authenticated) {
      navigate("/dashboard");
      return;
    }
    return (
      <div className="App">
        <Header publicHeader={true} />
        <PublicNavbar />
        <Outlet />
        <Footer />
      </div>
    );
  } else {
    return <RefreshScreen />;
  }
};

export default PublicLayout;
