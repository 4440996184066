import React from "react";

function Footer() {
  return (
    <div className="footer mt-auto d-flex justify-content-center p-3 text-center text-light fs-6  fw-bold position-sticky ">
      <p className="m-0">Copyright © 2024, <a className="text-light text-decoration-underline" href="https://poditservices.com/">POD IT Services</a>, All Rights Reserved.</p>
    </div>
  );
}

export default Footer;
