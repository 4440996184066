import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import TaskBlock from "./TaskBlock";
import TaskCreationComponent from "./TaskCreationComponent";
import ReportHeading from "./ReportHeading";
import { postReport, getActiveTaskList } from "../api/apiCalls";
import { useAlert } from "../context/AlertContext";
import { useApiCall } from "../middleware/useApiCall";

const ReportCardSmallChild = (props) => {

  const navigate = useNavigate();
  const apiCallMiddleware = useApiCall();
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const [tasks, setTasks] = useState({todayTasks: [], tomorrowTasks: []});
  const [invalidFields, setInvalidFields] = useState({});
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    const filterObject = { status: ["created", "onhold", "helpRequired", "inprogress"] };
    apiCallMiddleware(getActiveTaskList, filterObject).then((value) => {
      const activeTaskList = value?.map((v) => ({ ...v, description: "", spentTime: "" }) ?? []);
      setTasks({
        todayTasks: activeTaskList,
        tomorrowTasks: []
      });
    }).catch(error => console.error(error));
  }, [apiCallMiddleware]);


  const addTask = (taskDay)=>{
    const v = {name:"", description:"", status:"", estimateTime:"", spentTime:"" }
    const newArr = [...tasks[taskDay],v ]
    setTasks(prevTasks => ({...prevTasks, [taskDay]: newArr}));
  }


  const updateTodayTasks = (task)=>{
    const newarra = tasks.todayTasks.map((v,i)=>{return (i === task.id)?task:v})
    setTasks(prevTasks => ({...prevTasks, todayTasks: newarra}))
  }

  const updateTomorrowTasks = (task)=>{
    const newarra = tasks.tomorrowTasks.map((v,i)=>{return (i === task.id)?task:v})
    setTasks(prevTasks => ({...prevTasks, tomorrowTasks: newarra}))
  }

  const deleteTodayTask = (id)=>{
    const newArra = tasks.todayTasks.filter( (v, i)=>i !== id )
    setTasks(prevTasks => ({...prevTasks, todayTasks: newArra}));
  }

  const deleteTomorrowTask = (id)=>{
    const newArra = tasks.tomorrowTasks.filter( (v, i)=>i !== id )
    setTasks(prevTasks => ({...prevTasks, tomorrowTasks: newArra}));
  }

  const validateReport = useCallback(() => {
    let newInValidFields = {};
    tasks.todayTasks.forEach((task, index) => {
    let inValidFieldsForTask = {};
    if (!task.name || task.name.trim() === "") {
      inValidFieldsForTask.name = true;
    }
    if (!task.status) {
      inValidFieldsForTask.status = true;
    }
    if (task.estimateTime === "") {
      inValidFieldsForTask.estimateTime = true;
    }
    if (task.status === "open" && task.spentTime === "") {
      inValidFieldsForTask.spentTime = true;
    }
    newInValidFields = {
      ...newInValidFields,
      [index]: inValidFieldsForTask,
    };
    });
    setInvalidFields(newInValidFields);
    return Object.values(newInValidFields).some(taskInvalidFields => Object.keys(taskInvalidFields).length)
  }, [tasks.todayTasks])

  // functionality of submitting report
  async function submitReport() {
    const isInvalid = validateReport();
    if(isInvalid){
      showErrorAlert("Please fill in the required fields.")
    } else {
      try {
        const report = await postReport({todayTasks: tasks.todayTasks, tomorrowTasks: tasks.tomorrowTasks, date:props.date});
        if(report instanceof Object ){
          showSuccessAlert("Report successfully submitted!");
          navigate("/dashboard");
        } else {
          showErrorAlert("Report couldn't be submitted! Please try again later.");
        }
      } catch (error) {
        showErrorAlert(error.message);
      }
    }
  }

  useEffect(() => {
    if(submitClicked){
      validateReport();
    }
  }, [tasks, submitClicked, validateReport]);

  return (
    <>
    <ReportHeading {...props} expand={true} statusText="Preparing"/>
    <div
      className="report-form text-center"
    >

      <div className="mb-2">
      <div className="d-flex align-items-center">
        <h5 className="report-heading">Tasks</h5>
        <button type="button" className="create-btn button"
         onClick={() => addTask("todayTasks")}
         >
          <i className="bi bi-plus-square"></i>
        </button>
      </div>
      <div className="d-flex flex-column gap-3 justify-content-center border border-2 rounded p-3">
        {tasks.todayTasks.length !== 0 ? (
          tasks.todayTasks.map((task, i) =>
          <TaskCreationComponent
           key={i}
           id={i}
           taskDay="todayTasks"
           taskId = {task.taskId}
           name={task.name}
           status={task.status}
           estimateTime={task.estimateTime}
           spentTime={task.spentTime}
           description={task.description}
           updateTask ={updateTodayTasks}
           deleteTask={deleteTodayTask}
           invalidFields={invalidFields[i]}
           />
           )
        ) : (
          <button
            type="button"
            className="add-task-btn button"
            onClick={() => addTask("todayTasks")}
          >
            Click here to add task
          </button>
        )}
      </div>
    </div>

    {/* <div className="mb-2">
      <div className="d-flex align-items-center">
        <h5 className="report-heading">Tomorrow's Task</h5>
        <button type="button" className="create-btn button"
        onClick={() => addTask("tomorrowTasks")}
        >
          <i className="bi bi-plus-square"></i>
        </button>
      </div>
      <div className="d-flex flex-column gap-3 justify-content-center border border-2 rounded p-3">
        {tasks.tomorrowTasks.length !== 0 ? (
          tasks.tomorrowTasks.map((task, i) =>
          <TaskCreationComponent
           key={i}
           id={i}
           taskDay="tomorrowTasks"
           taskId = {task.taskId}
           name={task.name}
           status={task.status}
           description={task.description}
           updateTask ={updateTomorrowTasks}
           deleteTask={deleteTomorrowTask}
           />
           )
        ) : (
          <button
            type="button"
            className="add-task-btn button"
            onClick={() => addTask("tomorrowTasks")}
          >
            Click here to add task
          </button>
        )}
      </div>
    </div> */}

      <button id="submit-btn" className="btn px-5 py-2 mt-2 mb-3 rounded" onClick={() => {
        setSubmitClicked(true);
        submitReport()
        }
      }>
        Submit
      </button>
    </div>
    </>
    
  );
};

export const ReportCardCreator = (props) => {
  const [showSubmitForm, setShowSubmitForm] = useState(false);


  const onClick = ()=>{
    console.log("onclick called", )
    setShowSubmitForm(!showSubmitForm)
  }

  const render = ()=>{
    if(showSubmitForm)
      return <ReportCardSmallChild {...props} onclick={onClick} />
    return <ReportHeading {...props} onclick={onClick} expand={showSubmitForm} statusText="Not Submitted"/>
  }
  
  return (
    <div className="card report-creator">
      <div className="card-body">
      {render()}
      </div>
    </div>
  );
};

const ReportCard = (props) => {

  return (
  <Link to={`/dashboard/${props.subordinateReport ? "subordinate-reports" : "my-reports"}/${props.reportId}`} >
    <div className="card report" role="button">
      <div className="card-body">
        <ReportHeading {...props} statusText="Submitted" />
        {
          <div className="d-flex align-items-center">
            <div className="w-100 d-flex gap-3">
              {props.linkedTasks.map((taskBlock, i) => (
                <div key={i} className="col">
                  <TaskBlock taskBlock={taskBlock} showDescription={false}/>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </div>
  </Link>
  );
};

export default ReportCard;
