import React from "react";
import hourglass from "../../assets/hourglass.gif";

import "./styles.scss";

function RefreshScreen() {
  return (
    <div className="hourglass-container">
      <img className="hourglass" src={hourglass} alt="hourglass-animation" />
      <h6>Please wait while connecting...</h6>
    </div>
  );
}

export default RefreshScreen;
