export function getTourSteps(pathname) {
  const regex = new RegExp(/^\/dashboard\/(my|subordinate)-reports\/[^/]+/);
  if(regex.test(pathname)){
    return [
      {
        selector: null,
        content: (
          <p>
            This is <strong>Report Details</strong> page.
            <br /> Here you can view your reports in detail and add comments.
          </p>
        ),
        position: "center",
      },
      {
        selector: ".reply-btn",
        content: "Click on this button to leave a comment on the task."
      }
    ]
  }
  switch(pathname) {
    case "/dashboard":
      return [
        {
          selector: ".logo-img",
          content: (
            <div>
              <h3>Tasks Report</h3>
              <p>
                <strong>Tasks Report</strong> is a comprehensive web application
                designed to streamline <strong>daily reporting</strong> processes
                for individuals and teams. With Tasks Report, users can effortlessly
                submit and access their daily reports, as well as review reports
                submitted by their subordinates. The platform features robust{" "}
                <strong>user management</strong> capabilities, enabling
                administrators to easily update user details and permissions.
                Simplify your daily reporting tasks and enhance team collaboration
                with Tasks Report.
              </p>
            </div>
          ),
        },
        {
          selector: "#dashboard",
          content: (
            <p>
              <strong>Dashboard</strong> will give you an overview of reports
              submission analytics.
            </p>
          ),
        },
        {
          selector: "#my-reports",
          content: (
            <p>
              <strong>My Reports</strong> lets you create and view your reports.
            </p>
          ),
        },
        {
          selector: "#subordinate-reports",
          content: (
            <p>
              <strong>Subordinate Reports</strong> lets you view reports submitted
              by your subordinates.
            </p>
          ),
        },
        {
          selector: "#admin",
          content: (
            <p>
              <strong>Admin</strong> section is only available for users with admin
              role and allows user to perform user management operations.
            </p>
          ),
        },
        {
          selector: "#total-reports",
          content: "Total number of report that you should've submitted till date.",
        },
        {
          selector: "#pending-reports",
          content: "Number of pending report that you should submit till date.",
        },
        {
          selector: ".home-calendar",
          content: (
            <p>
              1. Dates in red meaning that report has not been submitted for that
              date.
              <br />
              2. Dates in black meaning that report has been submitted. <br />
              3. You can click on a particular date to see and submit report of that
              date.
            </p>
          ),
        },
      ]
    case "/dashboard/my-reports":
      return [
        {
          selector: null,
          content: (
            <p>
              This is <strong>My Reports</strong> page.
              <br /> Here you can create and view your daily reports.
            </p>
          ),
          position: "center",
        },
        {
          selector: ".report-creator",
          content: (
            <p>
              Click on <i className="bi bi-plus-square text-danger"></i> sign to
              start creating the report.
            </p>
          ),
          mutationObservables: [".report-creator"],
          resizeObservables: [".report-creator"],
        },
        {
          selector: ".filter-btn",
          content: "Reports' filter: Allow you to filter on Date or Month.",
        },
      ]
    case "/dashboard/subordinate-reports":
      return [
        {
          selector: null,
          content: (
            <p>
              This is <strong>Subordinate Reports</strong> page.
              <br /> Here you can view daily reports of your subordinates.
            </p>
          ),
          position: "center",
        },
        {
          selector: ".filter-btn",
          content: "You can apply filters using this button!",
        },
      ]
    case "/dashboard/users":
      return [
        {
          selector: null,
          content: (
            <p>
              <strong>User list</strong> shows all the users available into the
              system.
              <br />
              user with Admin role can manage the users here.
            </p>
          ),
          position: "center",
        },
        {
          selector: ".user-info",
          content: "This is information of a particular user.",
        },
        {
          selector: ".actions",
          content: "Click on the image will open user update section.",
        },
        {
          selector: ".task-delete-btn",
          content: "Click on the image will delete the user.",
        },
        {
          selector: "#create-user",
          content: "You can create a user by clicking on this button",
        },
        {
          selector: "#filter-button",
          content:
            "You can choose to filter users by their name or by manager's name. ",
        },
      ]
    case "/dashboard/users/update-user":
      return [
        {
          selector: null,
          content: (
            <p>
              This is the <strong>Update User</strong> page and only users with
              admin rights are allowed to access this page.
              <br />
              Here you can update the details of the user.
            </p>
          ),
          position: "center",
        },
        {
          selector: "#user-name",
          content: "You can update the name of the user from here.",
        },
        {
          selector: "#user-email",
          content: "This is the email field for the user and it cannot be changed.",
        },
        {
          selector: "#user-role",
          content: "You can update the role of the user from here.",
        },
        {
          selector: "#user-manager",
          content: "This field is to update the manager of the user.",
        },
        {
          selector: "#user-recipients",
          content:
            "This field decides who all will be notified when user submits their report.",
        },
      ]
    case "/dashboard/users/create-user":
      return [
        {
          selector: null,
          content: (
            <p>
              This is the <strong>Create User</strong> page and only users with
              admin rights are allowed to access this page.
              <br />
              Here you can create new users.
            </p>
          ),
          position: "center",
        },
        {
          selector: "#user-name",
          content: "Enter the name of user here",
        },
        {
          selector: "#user-email",
          content: "Enter the email of user here",
        },
        {
          selector: "#user-role",
          content: "Select user's role",
        },
        {
          selector: "#user-manager",
          content: "Select a manager for this user",
        },
        {
          selector: "#user-recipients",
          content:
            "This field decides who all will be notified when user submits their report.",
        },
      ]
    default:
      return []
  }
}