import { createSlice } from "@reduxjs/toolkit";
import { getSubordinateTimeSheetAction, getTimeSheetAction } from "./actions";

const initialState = {
  timeSheetData: [],
  subordinateTimeSheetData: [],
};

const timeSheetSlice = createSlice({
  name: "timeSheet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTimeSheetAction.fulfilled, (state, action) => {
        state.timeSheetData = action.payload;
      })
      .addCase(getTimeSheetAction.rejected, (state) => {
        state.timeSheetData = []
      })
      .addCase(getSubordinateTimeSheetAction.fulfilled, (state, action) => {
        state.subordinateTimeSheetData = action.payload;
      })
      .addCase(getSubordinateTimeSheetAction.rejected, (state) => {
        state.subordinateTimeSheetData = []
      });
  },
});

export default timeSheetSlice.reducer;
