import React from "react";

function LicensePriceCard({
  title,
  subtitle,
  buttonText,
  onClick,
}) {
  return (
    <div className="col-12 col-md-5 col-xl-4">
      <div className="card mb-4 rounded-4 shadow h-100 ">
        <div className="card-header py-3 rounded-top-4 border-0">
          <h4 className="my-0 fw-bold text-dark text-center ">{title}</h4>
        </div>
        <div className="card-body d-flex flex-column">
          <ul className="mt-2 list-unstyled">
            {subtitle.map((value) => <li className="text-secondary fw-medium " key={value}><span >{subtitle.length > 1 ? '> ' : ''}</span>{value}</li>)}
          </ul>
          <button
            type="button"
            className="w-100 rounded-4 btn btn-lg fw-bold btn-outline-dark border-2 mt-auto"
            onClick={onClick}
            >
              {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default LicensePriceCard;
