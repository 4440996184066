import { createSlice } from "@reduxjs/toolkit";
import { getSubordinateReports } from "./actions";

const initialState = {
  error: null,
  subordinateReports: null,
};

export const subordinateReportsSlice = createSlice({
  name: "subordinateReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubordinateReports.fulfilled, (state, action) => {
      state.error = null;
      state.subordinateReports = action.payload.data;
    });
    builder.addCase(getSubordinateReports.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default subordinateReportsSlice.reducer;
