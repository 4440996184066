import React, { useState } from "react";
import Alert from "../components/Alert/Alert";
import { useApiCall } from "../middleware/useApiCall";
import { useAlert } from "../context/AlertContext";
import { useKeycloak } from "@react-keycloak/web";
import User from "../utils/userInfo";
import logo from "../assets/podit_logo.png";
import Header from "../components/Header";

class subscribeApiCall {
  constructor(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
  }

  async processResponse(response) {
    if (response.ok) {
      const jsonResponse = await response.json();
      if (jsonResponse.status === 0 || jsonResponse.status === 6 ) {
        return jsonResponse.data;
      } else {
        console.error(jsonResponse.error);
        const error = new Error();
        error.status = jsonResponse.status;
        error.statusText = jsonResponse.error;
        throw error;
      }
    } else {
      console.error(response.statusText);
      const error = new Error();
      error.status = response.status;
      error.statusText = response.statusText;
      throw error;
    }
  }

  async subscribe(requestBody) {
    const response = await fetch(this.apiBaseUrl + "/subscribe", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${User.getKeycloakToken()}`,
      },
      method: "POST",
      body: JSON.stringify(requestBody),
    });
    return await this.processResponse(response);
  }
}

const subscribeApiCaller = new subscribeApiCall(process.env.REACT_APP_SUBSCRIPTION_BASE_URL);

async function subscribe({ service, info }){
  const value = await subscribeApiCaller.subscribe({ service, info })
  return value
}

const UserNotFound = () => {
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const apiCallMiddleware = useApiCall();
  const { keycloak } = useKeycloak();
  const [registrationForm, setRegistrationForm] = useState({ tenancy: "" });

  // function for handling change by user in input fields
  function handleChange(event) {
    const { name, value } = event.target;
    setRegistrationForm({ ...registrationForm, [name]: value });
  }

  // function for handling click of register button
  async function handleSubmit() {
    try {
      let {tenancy} = registrationForm;

      const {name , email} = await keycloak.loadUserInfo();

      // calling subscribe api
      await apiCallMiddleware(subscribe, {
        service: "dailyReport",
        info: {tenancy, name, email},
      });
      showSuccessAlert("Registered successfully!");
      window.location.reload();
    } catch (error) {
      console.error(error);
      showErrorAlert(error.statusText);
    }
  }

  function navigateToContactUs() {
    window.open(process.env.REACT_APP_PODIT_CONTACT_US_URL, "_blank");
  }

  return (
    <>
    <Header publicHeader={true} helpAction={navigateToContactUs} showUserIcon={false}/>
    <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-black ">
      <div className="card col-sm-8 col-md-6 col-xl-4 p-4">
        <div className=" d-flex  align-content-center justify-content-center ">
        <img className=" w-25 " src={logo} alt="LOGO"/>
        </div>
        <div className="card-body">
          <div className="registration-card-title fw-bold ">Register with us</div>
          <div className="add-sub-form d-flex flex-column gap-4">
            <label className="form-label fw-bold">
              Your Organization Name
              <input
                type="text"
                className="form-control mt-2 shadow-sm "
                name="tenancy"
                value={registrationForm.tenancy}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="d-flex flex-row justify-content-around mt-3">
            <button
              id="secondary-btn"
              className="btn px-5 py-2 mt-2 mb-3 rounded shadow-sm "
              onClick={() =>
                keycloak.logout({
                  redirectUri: window.location.origin,
                })
              }
            >
              Cancel
            </button>
            <button
              id="submit-btn"
              className="btn px-5 py-2 mt-2 mb-3 rounded shadow-sm "
              onClick={handleSubmit}
              disabled={Object.values(registrationForm).every(
                (value) => !value
              )}
            >
              Register
            </button>
          </div>
        </div>
      </div>
      <Alert className="w-100 mx-0 mb-5 " />
    </div>
    <footer className=" fixed-bottom bg-white d-flex justify-content-end z-0 ">
      <h4 className="footer-text">
        Copyright &copy; 2024
      </h4>
    </footer>
  </>
  );
};

export default UserNotFound;
