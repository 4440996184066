import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSubordinateReportsWithTaskDetails } from "../../api/apiCalls";

export const getSubordinateReports = createAsyncThunk(
  "subordinateReports/getSubordinateReports",
  async (data, thunkApi) => {
    try {
      const response = await getSubordinateReportsWithTaskDetails(
        data && data.startDate,
        data && data.endDate,
        data && data.users
      );
      return { data: response };
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
