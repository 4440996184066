
import User from "./userInfo";
import { authenticate } from "../api/apiCalls";

export async function apiAuthenticate(user) {
    const userInfo = await authenticate()
    if (userInfo.token) {
        User.setToken(userInfo.token);
        if (user) {
            User.setUserInfo({ ...user, ...userInfo });
        }
    } else{
       throw new Error("api authentication failed")
    }
}
