import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-date-picker/dist/DatePicker.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { AlertProvider } from "./context/AlertContext";
import { TourProvider } from "@reactour/tour";
import { Provider } from 'react-redux';
import store from './redux/store';
import keycloak from './Keycloak';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store} >
  <ReactKeycloakProvider authClient={keycloak} >
    <TourProvider showCloseButton={false}  className="tour-popover">
      <AlertProvider>
        <App />
      </AlertProvider>
    </TourProvider>
  </ReactKeycloakProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
