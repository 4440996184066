export default function timeSheetFormatter(timeSheet) {
    const dateWiseObject =  timeSheet.dates.reduce((accumulator, dailyData) => {
        accumulator[new Date(dailyData.date).getDate()] = dailyData.totalspenthours
        return accumulator;
    }, {});

    return {
        hours: dateWiseObject,
        user_id: timeSheet.user_id
    }
}