import { Routes, Route, BrowserRouter } from "react-router-dom";
import DashboardLayout from "./pages/DashboardLayout";
import Dashboard from "./pages/Dashboard";
import SubordinateReports from "./pages/SubordinateReports";
import MyReports from "./pages/MyReports";
import ReportDetails from "./pages/ReportDetails";
import Users from "./pages/Users";
import UpdateUser from "./pages/UpdateUser";

import PrivateRoute from "./PrivateRoute"; 
import CreateUser from "./pages/CreateUser";
import Home from "./pages/Home";
import License from "./pages/License";
import PublicLayout from "./pages/PublicLayout";
import SubordinateReportDetails from "./pages/SubordinateReportDetails";
import MyTimeSheet from "./pages/MyTimeSheet";
import SubordinateTimeSheet from "./pages/SubordinateTimeSheet";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicLayout />}>
          <Route index element={<Home />} />
          <Route path="license" element={<License />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="my-reports" element={<MyReports />} />
            <Route
              path="subordinate-reports"
              element={<SubordinateReports />}
            />
            <Route path="my-reports/:reportId" element={<ReportDetails />}/>
            <Route path="subordinate-reports/:reportId" element={<SubordinateReportDetails />}/>
            <Route path="my-timesheet" element={<MyTimeSheet />} />
            <Route path="subordinate-timesheet" element={<SubordinateTimeSheet />} />
            <Route path="users" element={<Users />} />
            <Route path="users/create-user" element={<CreateUser />} />
            <Route path="users/update-user" element={<UpdateUser />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
