export function getTaskStatusForApi(status) {
  switch (status) {
    case "open":
      return "inprogress";
    case "closed":
      return "completed";
    case "help":
      return "helpRequired";
    case "onhold":
      return "onhold"
    case "created":
      return "created"
  }
}

export function getTaskStatusForUI(status) {
  switch (status) {
    case "inprogress":
      return "open";
    case "completed":
      return "closed";
    case "helpRequired":
      return "help";
    case "onhold":
      return "onhold";
    case "created":
      return "created";
    default:
      return "open";
  }
}

export function displayStatus(status) {
  switch(status) {
    case "open": 
      return "In Progress";
    case "closed":
      return "Closed";
    case "help":
      return "Help";
    case "onhold":
      return "On Hold";
    case "created":
      return "Open";
    default:
      return "Open";
  }
}