export function isSameDay(a, b) {
  const startDate = new Date(a);
  const endDate = new Date(b);
  return (
    Math.abs(startDate.getTime() - endDate.getTime()) < 86400000 &&
    startDate.getDate() === endDate.getDate()
  );
}

export function numberOfDaysBetween(a, b) {
  const startDate = new Date(a);
  const endDate = new Date(b);
  return (
    Math.trunc(Math.abs(startDate.getTime() - endDate.getTime()) / 86400000) + 1
  );
}

export function firstAndLastDateOfMonth(a) {
  const dateObject = new Date(a);
  const firstDate = new Date(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    1
  );
  firstDate.setMinutes(firstDate.getMinutes() - firstDate.getTimezoneOffset());
  const lastDate = new Date(
    dateObject.getFullYear(),
    dateObject.getMonth() + 1,
    0
  );
  lastDate.setMinutes(lastDate.getMinutes() - lastDate.getTimezoneOffset());
  return { firstDate, lastDate };
}

export function dateInApiFormat(a) {
  const dateObject = new Date(a);
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  return `${year}-${month}-${day}`;
}

export function dateInUIFormat(a) {
  const date = new Date(a);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year  = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function UIDateToObject(a) {
  const [ date, month, year ] = a.split('/');
  const dateObject = new Date(year, month - 1, date);
  dateObject.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
  return dateObject;
}

export function getMonthFromDate(a) {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date(a);
  return month[date.getMonth()];
}
