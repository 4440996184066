import { Outlet } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import RefreshScreen from "./pages/RefreshPage/RefreshScreen";
import Subscribe from "./pages/Subscribe";
import User from "./utils/userInfo";
import { apiAuthenticate } from "./utils/apiAuthenticator";
import ErrorPage from "./pages/ErrorPage/ErrorPage";

const PrivateRoute = () => {
  const { keycloak, initialized } = useKeycloak();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const authenticatingFromServer = useCallback(async () => {
    User.setKeycloakToken(keycloak.token);
    const user = await keycloak.loadUserInfo();
    try {
      await apiAuthenticate(user);
    } catch (err) {
      setError({
        backendError : err.backendError,
        status: err.status || err.name,
        statusText: err.statusText || err.message,
      });
    } finally {
      setLoading(false);
    }
  }, [keycloak]);

  const loginAgain = () => {
    keycloak.logout();
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      authenticatingFromServer();
    }
  }, [keycloak.authenticated, authenticatingFromServer]);

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      // Redirect to Keycloak login page
      window.location.href = keycloak.createLoginUrl({
        redirectUri: window.location.href,
      });
    }
  }, [initialized, keycloak]);

  if (!initialized) {
    return <RefreshScreen />;
  }
  if (keycloak.authenticated) {
    console.log("outlet", keycloak);
    console.log("user email ", keycloak);
    if (loading) {
      return <RefreshScreen />;
    }
    if (error) {
      if (error.backendError && error.status === 8) {
        return <Subscribe />;
      } else {
        return (
          <ErrorPage
            error={error}
            buttonText="Login Again"
            buttonOnClick={loginAgain}
          />
        );
      }
    } else {
      return <Outlet />;
    }
  }
};

export default PrivateRoute;
