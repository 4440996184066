import React, { useEffect, useState, useCallback } from "react";
import UserFilter from "../components/UserFilter";
import UsersTable from "../components/UsersTable";
import { getUsers } from "../api/apiCalls";
import { useApiCall } from "../middleware/useApiCall";
import { Link } from "react-router-dom";

const Users = () => {
  const apiCallMiddleware = useApiCall();
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState([])
  const [showFilter, setShowFilter] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [userFilter, setUserFilter] = useState([]);

  // handling filter selection
  function handleFilterSelection(event, name, id) {
    setUserFilter((prevUserFilter) =>
      prevUserFilter.map((filter) =>
        filter.name === name
          ? {
              ...filter,
              value: filter.value.map((value) =>
                value.id === id
                  ? { ...value, isSelected: event.target.checked }
                  : value
              ),
            }
          : filter
      )
    );
  }

  // functionality for clering all selected filter
  function clearFilter() {
    setUserFilter((prevUserFilter) =>
      prevUserFilter.map((filter) => ({
        ...filter,
        value: filter.value.map((value) => ({ ...value, isSelected: false })),
      }))
    );
    setShowFilter(false);
    setNameFilter("");
  }

  // checking if any filter dropdown is selected
  const isAnyFilterDropdownSelected = useCallback(() => {
    for (const filter of userFilter) {
      for (const value of filter.value) {
        if (value.isSelected) {
          return true;
        }
      }
    }
    return false;
  }, [userFilter]);

  // check if either any dropdown filter is selected or if search filter is active
  function isAnyFilterApplied() {
    return isAnyFilterDropdownSelected() || nameFilter;
  }

  function filteredUsersList() {
    let usersArray = totalUsers;
    let selectedEmployees = [];
    let selectedManagers = [];

    // getting selected filters
    [selectedEmployees, selectedManagers] = userFilter.map((filterOption) => (
      filterOption.value
        .filter((filterValue) => filterValue.isSelected)
        .map((filterValue) => filterValue.id))
    );

    // filtering usersArray based on selected filters
    if (selectedEmployees.length > 0 || selectedManagers.length > 0) {
      usersArray = usersArray.filter(
        (user) =>
          (selectedEmployees.length === 0 ||
            selectedEmployees.includes(user.id)) &&
          (selectedManagers.length === 0 ||
            selectedManagers.includes(user.manager.id))
      );
    }
    return usersArray;
  }

  // getting users array and users filter
  const usersDataAndFilter = useCallback (async () => {
    try {
      const usersArray = (await apiCallMiddleware(getUsers)) ?? [];
      const usersData = usersArray.map((element) => {
        // finding manager of the user
        const user = usersArray.find((user) => user.id === element.manager);
        const manager = user && { id: user.id, name: user.name };
        // finding additional recipients of the user
        const additionalRecipients = element.notification_email.map((recipient) => {
          const searchedRecipient = usersArray.find((user) => user.id === recipient);
          if(searchedRecipient) {
            let recipientObject = { id: searchedRecipient.id, name: searchedRecipient.name }
            return recipientObject;
          }
        })
        // finding subordinates of the user
        const subordinates = usersArray
          .filter(user => user.manager === element.id)
          .map(user => ({id: user.id, name: user.name}));
        return {
          ...element,
          manager: manager ?? '',
          additional_recipients: additionalRecipients ?? [],
          subordinates: subordinates ?? [],
        };
      });

      setTotalUsers(usersData);
      setUserFilter([
        {
          name: "Employee",
          hintText: "Users",
          value: usersData.map((user) => ({
            id: user.id,
            displayValue: user.name,
            isSelected: false,
          })),
        },
        {
          name: "Manager",
          hintText: "Manager",
          value: usersData.reduce((acc, user) => {
            if (
              !acc.find((manager) => manager.id === user.manager.id) &&
               user.manager !== ""
            ) {
              acc.push({
                id: user.manager.id,
                displayValue: user.manager.name,
                isSelected: false,
              });
            }
            return acc;
          }, []),
        },
      ]);
    } catch (error) {
      console.error(error);
    }
  }, [apiCallMiddleware])

  useEffect(() => {
    usersDataAndFilter();
  }, [apiCallMiddleware, usersDataAndFilter]);

  useEffect(() => {
    if (isAnyFilterDropdownSelected()) {
      setUsers(filteredUsersList());
    } else {
      setUsers(totalUsers);
    }
  }, [userFilter, isAnyFilterDropdownSelected]);

  return (
    <main id="main" className="main users">
      <div className="pagetitle d-flex align-items-center">
        <h1>Users</h1>
        <div className="d-flex flex-row gap-3 align-items-center ms-auto">
          <Link to ="create-user" state={{userArray : users}} id="create-user" className="filter-btn button">
            <i className="bi bi-plus-square"></i>
          </Link>
          <button
            type="button"
            className="filter-btn button"
            id="filter-button"
            onClick={() => setShowFilter((prevShowFilter) => !prevShowFilter)}
          >
            <i
              className={`bi bi-funnel ${
                isAnyFilterApplied() ? "filter-btn-active" : ""
              }`}
            ></i>
          </button>
        </div>
      </div>
      {showFilter && (
        <UserFilter
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          userFilter={userFilter}
          handleFilterSelection={handleFilterSelection}
          clearFilter={clearFilter}
        />
      )}
      <UsersTable
        users={users}
        nameFilter={nameFilter}
        usersDataAndFilter={usersDataAndFilter}
      />
    </main>
  );
};

export default Users;
