import React from "react";
import TimeSheetPage from "../components/TimeSheetPage";
import { getTimeSheetAction } from "../redux/timeSheet/actions";
import { timeSheetSelector } from "../redux/timeSheet/selector";
import User from "../utils/userInfo";

const MyTimeSheet = () => {
  const userInfo = User.getUserInfo();
  return (
    <TimeSheetPage
      title="My TimeSheet"
      dataFetchAction={getTimeSheetAction}
      dataSelector={timeSheetSelector}
      userIds={[userInfo.id]}
    />
  );
};

export default MyTimeSheet;
