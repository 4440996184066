import React from "react";
import "./errorPage.css";
import Header from "../../components/Header";

const ErrorPage = ({
  error,
  buttonText,
  buttonOnClick,
}) => {
  function navigateToContactUs() {
    window.open(process.env.REACT_APP_PODIT_CONTACT_US_URL, "_blank");
  }

  return (
    <div className="error-container d-flex align-items-center justify-content-center bg-black">
      <Header
        publicHeader={true}
        helpAction={navigateToContactUs}
        showUserIcon={false}
      />

      <div className="card col-sm-8 col-md-6 col-xl-4 p-3">
        <div className="d-flex align-content-center justify-content-center"></div>
        <div className="card-body">
          <h2 className="error-card-title fw-bold">Error</h2>
          <div className="text-danger text-center my-2 error-text fw-bolder">
            {error.backendError ? (
              <span>Not authorized. Please contact admin!</span>
            ) : (
              <span>{error.statusText}</span>
            )}
          </div>
          <div className="d-flex flex-row justify-content-around mt-3">
            {buttonText && (
              <button
                id="submit-btn"
                className="btn px-4 py-2 mt-2 mb-3 rounded shadow-sm"
                onClick={buttonOnClick}
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
