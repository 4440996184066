/**
 * Returns the comment array, formatted for display in the UI.
 * If comment is object, it will be displayed as "Name: Comment" else it will return the comment itself.
 * @param {Comment[]} commentArray - The array of comments.
 * @returns {Comment[]} commentArray - formatted for display in the UI.
 */
export function commentFormatter(commentArray) {
  return commentArray.map((comment) =>
    comment instanceof Object
      ? `${comment.from}: ${comment.comment}`
      : comment
  );
}
