import {
  isSameDay,
  firstAndLastDateOfMonth,
  dateInApiFormat,
  dateInUIFormat,
} from "../utils/dateUtils";
import { getTaskStatusForApi, getTaskStatusForUI } from "../constant/statusMap";
import User from "../utils/userInfo";
import { commentFormatter } from "../formatters/taskCommentFormatter";

export default class serverApiCall {
  constructor(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
  }

  // a generic function to make calls to server and get data
  async requestToServer(endpoint, requestObject) {
      const response = await fetch(this.apiBaseUrl + endpoint, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${User.getToken()}`,
          "Page": window.location.href
        }, 
        ...requestObject
      });
      return await this.processResponse(response)
  }

  async processResponse(response){
    if (response.ok) {
      const contentType = response.headers.get("Content-Type")
      if (contentType === "application/json") {
        const jsonResponse = await response.json();
        if (jsonResponse.status === 0) {
          return jsonResponse.data;
        } else {
          console.error(jsonResponse.error);
          const error = new Error();
          error.backendError = true;
          error.status = jsonResponse.status;
          error.message = jsonResponse.error;
          throw error;
        }
      } else if (
        contentType === "application/octet-stream" ||
        contentType === "application/pdf"
      ) {
        return response.blob();
      } else {
        const error = new Error();
        error.message = "Response Format not supported!"
        throw error;
      }
    } else {
      console.error(response.statusText);
      const error = new Error()
      error.status = response.status;
      error.message = response.statusText;
      throw error;
    }
  }


  async getUserInfo() {
    let user = await this.requestToServer("/getProfile", {
      method: "POST",
      body: JSON.stringify({}),
    });
    if (user instanceof Object) {
      user = {
        name: user.name,
        email: user.email,
        id: user.id,
        isAdmin: user.role === "admin",
        designation: user.designation,
        imgURL: user.profilePic,
      };
      return user;
    }
    return {};
  }

  async createTask(taskInfo, userId) {
    const task = await this.requestToServer("/task", {
      method: "POST",
      body: JSON.stringify({...taskInfo, user: { id: userId }}),
    });
    return task ?? {};
  }

  async updateTask(taskInfo) {
    const task = await this.requestToServer("/task", {
      method: "PUT",
      body: JSON.stringify(taskInfo),
    });
    return task ?? {};
  }

  async getTaskDetails(taskId) {
    let task = await this.requestToServer("/getTaskbyId", {
      method: "POST",
      body: JSON.stringify({ ids: taskId }),
    });
    return task;
  }

  async getReportById(reportId) {
    let report = await this.requestToServer("/getDailyReport", {
      method: "POST",
      body: JSON.stringify({ id: reportId }),
    });
    return report;
  }

  async postReport(report) {
    const userId = User.getUserInfo().id;
    if (report instanceof Object) {
      const reportBody = {
        reportDate: dateInApiFormat(report.date),
        todayTasks: report.todayTasks.map((task) => ({
          reportDate: dateInApiFormat(report.date),
          title: task.name,
          status: getTaskStatusForApi(task.status),
          comment: task.description ? [task.description] : undefined,
          id: task.taskId,
          estimateTime: task.estimateTime ? parseFloat(task.estimateTime) : undefined,
          spentTime: task.spentTime ? parseFloat(task.spentTime) : undefined
        })),
        tomorrowTasks: report.tomorrowTasks.map((task) => ({
          title: task.name,
          status: getTaskStatusForApi(task.status),
          comment: task.description ? [task.description] : undefined,
          id: task.taskId,
        })),
        user:{
          id: userId
        },
      };

      // updating and creating tasks before submitting report
        let existingTodayTasks= reportBody.todayTasks.filter(task=> task.id).map(task=>this.updateTask(task));
        await Promise.all(existingTodayTasks).then(tasks=> existingTodayTasks = tasks);

        let newTodayTasks = reportBody.todayTasks.filter(task=> !task.id).map(task=> this.createTask(task, userId));
        await Promise.all(newTodayTasks).then(tasks=> newTodayTasks = tasks);

        let existingTomorrowTasks= reportBody.tomorrowTasks.filter(task=> task.id).map(task=>this.updateTask(task));
        await Promise.all(existingTomorrowTasks).then(tasks=> existingTomorrowTasks =tasks);

        let newTomorrowTasks = reportBody.tomorrowTasks.filter(task=> !task.id).map(task=> this.createTask(task, userId));
        await Promise.all(newTomorrowTasks).then(tasks=> newTomorrowTasks = tasks);
      
      reportBody.todayTasks = [...existingTodayTasks, ...newTodayTasks];
      reportBody.tomorrowTasks = [...existingTomorrowTasks, ...newTomorrowTasks];

      // ajax call to submit report
      const data =await this.requestToServer("/dailyReport", {
        method: "POST",
        body: JSON.stringify(reportBody),
      });
      return data;
    }
  }

  async getUsers(filter = {}) {
    let data = await this.requestToServer("/userDetails", {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (data instanceof Array) {
      data = data.map((element) => ({
        ...element,
        code: element.id,
      }));
    }
    return data ?? [];
  }

  async createUser(userInfo){
    const data = await this.requestToServer("/user", {
      method: "POST",
      body: JSON.stringify(userInfo),
    });
    return data;
  }

  async deleteUsers(usersList){
    const data = await this.requestToServer("/deleteUsers", {
      method: "POST",
      body: JSON.stringify({users: usersList}),
    });
    return data;
  }

  // FIX ME: code in update user page UI should be a readonly field
  async putUser(userInfo) {
    if (userInfo instanceof Object) {
      const userInfoBody = {
        id: userInfo.code,
        email: userInfo.email,
        name: userInfo.name,
        role: userInfo.role,
        manager: userInfo.manager,
        notification_email: userInfo.recepients
          .filter((user) => user.isSelected)
          .map((user) => user.id),
      };
      const data = await this.requestToServer("/updateUser", {
        method: "PUT",
        body: JSON.stringify(userInfoBody),
      });
      return data;
    }
  }

  // FIX ME: In UI its showing user's id instead of name
  async getActiveUsers() {
    const data = await this.getUsers();
    if (data instanceof Array) {
      return data.map((user) => ({ code: user.id, name: user.name }));
    }
    return [];
  }

  async getReportsStats (startDate, endDate, userId) {
    const requestBody = {
      "startDate": dateInApiFormat(startDate),
      "endDate": dateInApiFormat(endDate),
      "user": {
        "id": userId,
      }
    }

    const reportsStats = await this.requestToServer("/getStats", {
      method: "POST",
      body: JSON.stringify(requestBody)
    });
    return reportsStats
  }

  async getAdminStats(startDate, endDate, userId) {
    const requestBody = {
      "startDate": dateInApiFormat(startDate),
      "endDate": dateInApiFormat(endDate),
      "user": {
        "id": userId,
      }
    }
    const adminStats = await this.requestToServer("/getAdminStats", {
      method: "POST",
      body: JSON.stringify(requestBody)
    });
    return adminStats;
  }

  async getReportsData(filter) {
    // FIX ME: start date and end date are for when user goes to My Reports page with no filters are selected
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    let endDate = new Date();
    const userId = User.getUserInfo().id;
    let reportsDataForUI = [];
    let finalResult = [];
    // setting startDate and endDate if filters are selected
    if (filter) {
      if (filter.monthFilter) {
        ({ firstDate: startDate, lastDate: endDate } = firstAndLastDateOfMonth(
          filter.monthFilter
        ));
        endDate = endDate > new Date() ? new Date() : endDate;
      } else if (filter.dateFilter) {
        startDate = new Date(filter.dateFilter);
        startDate.setMinutes(
          startDate.getMinutes() - startDate.getTimezoneOffset()
        );
        endDate = new Date(startDate);
      }
    }
    // call to /getReports API
    let reportsData = await this.requestToServer("/getReports", {
      method: "POST",
      body: JSON.stringify({
        startDate: dateInApiFormat(startDate),
        endDate: dateInApiFormat(endDate),
        user:{
          id: userId
        }
      }),
    });

    let taskIds = new Set();

    // making ajax calls to get tasks data and making object for UI to render data
    if (reportsData instanceof Array) {
      for (let report of reportsData) {
        report.todayTasks.forEach((task) => taskIds.add(task.id));
        report.tomorrowTasks.forEach((task) => taskIds.add(task.id));
      }

      const tasksList = await this.getTaskDetails(Array.from(taskIds));
      
      
      for (let report of reportsData) {
        
        report.todayTasks = report.todayTasks.map(task => {
          const taskInfo = tasksList.find(element => element.id === task.id)
          return {...taskInfo, status: task.status}
        })

        report.tomorrowTasks = report.tomorrowTasks.map(task => {
          const taskInfo = tasksList.find(element => element.id === task.id)
          return {...taskInfo, status: task.status}
        })

        const newReport = {
          date: report.reportDate,
          submitted: true,
          postedOn: report.createdDate,
          sentTo: report.user.notification_email,
          linkedTasks: [
            {
              date: "Today's Task",
              tasks: report.todayTasks?.map((task) => ({
                name: task.title,
                description: commentFormatter(task.comment),
                status: getTaskStatusForUI(task.status),
              })),
            },
            {
              date: "Tomorrow's Task",
              tasks: report.tomorrowTasks?.map((task) => ({
                name: task.title,
                description: commentFormatter(task.comment),
                status: getTaskStatusForUI(task.status),
              })),
            },
          ],
        }
        reportsDataForUI.push(newReport);
      }

      for (
        let date = startDate;
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        let report = reportsDataForUI.find((report) => isSameDay(date, report.date));
        let result = report
          ? {...report, date: dateInUIFormat(report.date), postedOn: dateInUIFormat(report.postedOn)}
          : { date: dateInUIFormat(date), submitted: false };
        finalResult.push(result);
      }
    }
    return finalResult;
  }
  async getReportWithTaskDetails(startDate, endDate, userId) {

    let reportsData = await this.requestToServer("/getReportWithTaskDetail", {
      method: "POST",
      body: JSON.stringify({
        startDate: dateInApiFormat(startDate),
        endDate: dateInApiFormat(endDate),
        user:{
          id: userId
        }
      }),
    });
    return reportsData;
  }

  async getActiveTaskList(filter) {
    let activeTaskList = await this.requestToServer("/getTaskList", {
      method: "POST",
      body: JSON.stringify(filter),
    });
    if (activeTaskList instanceof Array) {
      activeTaskList = activeTaskList.map((task) => ({
        taskId: task.id,
        name: task.title,
        status: getTaskStatusForUI(task.status),
        description: commentFormatter(task.comment),
      }));
    }
    return activeTaskList ?? [];
  }

  async authenticate() {
    const response = await fetch(this.apiBaseUrl + "/authenticate", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${User.getKeycloakToken()}`
      }, 
      method: "POST",
      body: JSON.stringify({}),
    });
    return await this.processResponse(response)
    
  }

  //FIXME- need to implement extracting data with startData and endDate inclusion
  async getSubordinateReports({startDate, endDate, users}) {
    
    // call to /getSubordinateReport API
    let reportsData = await this.requestToServer("/getSubordinateReport", {
      method: "POST",
      body: JSON.stringify({
        // startDate: dateInApiFormat(startDate), endDate: dateInApiFormat(endDate), 
        users: users})
    });
    return reportsData
}

async getSubordinateReportsWithTaskDetails(startDate, endDate, users) {
  const reportsData = await this.requestToServer("/getSubordinateReportWithTaskDetails", {
    method: "POST",
    body: JSON.stringify({
      startDate: startDate &&  dateInApiFormat(startDate),
      endDate: endDate && dateInApiFormat(endDate),
      users: users && users
    }),
  });
  return reportsData;
}

  async getSubordinates(queryObject) {
    const queryParams = new URLSearchParams(queryObject).toString();
    const subordinatesData = await this.requestToServer(`/subordinates?${queryParams}`);
    return subordinatesData;
  }

  async getTimeSheet(requestObject) {
    const timeSheetData = await this.requestToServer("/timesheet", {
      method: "POST",
      body: JSON.stringify(requestObject),
    })
    return timeSheetData;
  }
}