import React, { useEffect, useState } from "react";
import UserForm from "../components/UserForm";
import { useLocation, useNavigate } from "react-router-dom";
import { createUser } from "../api/apiCalls";
import { useAlert } from "../context/AlertContext";
import { useApiCall } from "../middleware/useApiCall";

const CreateUser = () => {
  const location = useLocation();
  const props = location.state;
  const activeUsers = props.userArray;
  const navigate = useNavigate();
  const { showErrorAlert, showSuccessAlert } = useAlert();
  const apiCallMiddleware = useApiCall();

  const [formState, setFormState] = useState({
    active: false,
    name: "",
    email: "",
    role: "standard",
    manager: "",
    recepients: activeUsers.map((user) => ({
      id: user.code,
      displayValue: user.name,
      isSelected: false,
    })),
  });

  function shouldDisableSubmitButton() {
    return formState.name === "" || formState.email === "";
  }

  function handleInputChange(event) {
    setFormState((prevFormState) => {
      if (event.target.type === "checkbox") {
        return { ...prevFormState, [event.target.name]: event.target.checked };
      }
      return {
        ...prevFormState,
        [event.target.name]: event.target.value,
      };
    });
  }

  function handleOptionSelection(event, name, id) {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: prevFormState[name].map((value) =>
        value.id === id ? { ...value, isSelected: event.target.checked } : value
      ),
    }));
  }

  async function handleFormSubmission() {
    try {
      const requestBody = {
        email: formState.email,
        name: formState.name,
        role: formState.role,
        manager: formState.manager,
        notification_email: formState.recepients
          .filter((recipient) => recipient.isSelected)
          .map((recipient) => recipient.id),
      };

      const result = await apiCallMiddleware(createUser, requestBody);
      if (result instanceof Object) {
        showSuccessAlert("User Created successfully!");
        navigate("/dashboard/users");
      } else {
        showErrorAlert("User couldn't be created! Please try again later.");
      }
    } catch (error) {
      showErrorAlert(error.message);
    }
  }

  return (
    <main id="main" className="main">
      <div className="pagetitle d-flex justify-content-between">
        <h1>Create User</h1>
      </div>
      <div className="container row justify-content-center">
        <div className="card col-sm-12  col-md-8 col-xl-6">
          <div className="card-body">
            <UserForm
              {...props}
              createUser={true}
              formState={formState}
              shouldDisableSubmitButton={shouldDisableSubmitButton}
              handleInputChange={handleInputChange}
              handleOptionSelection={handleOptionSelection}
              handleFormSubmission={handleFormSubmission}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateUser;
