import React from "react";

function Answer({ text }) {
  return text.length === 1 ? (
    <p>{text}</p>
  ) : (
    <div>
        <p className="mb-2">{text[0]}</p>
        <ul>
            {text.slice(1, text.length - 1).map((item, index) => (
                <li key={index}><p>{item}</p></li>
            ))}
        </ul>
        <p>{text[text.length - 1]}</p>
    </div>
  );
}

export default Answer;
