export function orderReports(reports) {
  // converting reports array into object with keys as dates and values as array of reports of that date
  const datesReportObject = reports.reduce((acc, report) => {
    if (!acc.hasOwnProperty(report.date)) {
      acc[report.date] = [];
    }
    acc[report.date].push(report);
    return acc;
  }, {});

  // converting the object into an array of entries, sort by date in descending order, and then flatten
  const reportsArrayByDate = Object.entries(datesReportObject)
    .sort((a, b) => new Date(b[0]) - new Date(a[0])) // sorting by date in descending order
    .reduce(
      (acc, dateReports) => [
        ...acc,
        ...dateReports[1], // spreading the array of reports for each date
      ],
      []
    );

  return reportsArrayByDate;
}
