import React from 'react'

const Tooltip = (props) => {
  return (
    <div className='tooltip-right'>
        <i className={`bi bi-${props.icon}`}></i>
        <span className='tooltiptext tooltiptext-arrow'>{props.text}</span>
    </div>
  )
}

export default Tooltip