import "./estimateSpentTime.css";
import React, { useState, useRef } from "react";
import useClickOutside from "../../utils/useClickOutside";

const EstimateSpentTime = ({
  estimateTime,
  spentTime,
  onupdate,
  invalidFields,
}) => {
  const effortDropdownRef = useRef(null);
  // closing effort dropdown when clicked outside of it
  useClickOutside(effortDropdownRef, () => setShowEffortDropdown(false));
  const [showEffortDropdown, setShowEffortDropdown] = useState(false);

  const invalidButtonStyle =
    invalidFields && (invalidFields.estimateTime || invalidFields.spentTime)
      ? { color: "var(--btn-danger-bg)" }
      : {};

  return (
    <div ref={effortDropdownRef} className="time-dropdown position-relative">
      <button
        style={invalidButtonStyle}
        className="time-btn button position-relative d-flex"
        onClick={() =>
          setShowEffortDropdown((prevShowTimeDropdown) => !prevShowTimeDropdown)
        }
      >
        <i className="bi bi-clock-history"></i>
      </button>
      {showEffortDropdown && (
        <ul className="dropdown-list time-dropdown-list d-flex flex-column position-absolute me-1 end-0 z-3">
          <h5 className="report-heading p-2">Effort (Hours)</h5>
          <hr />
          {estimateTime !== undefined && (
            <>
              <li className="p-2">
                <input
                  className={`form-control ${
                    invalidFields && invalidFields.estimateTime
                      ? "invalid-field"
                      : ""
                  }`}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder="Expected Hours"
                  value={estimateTime}
                  onChange={(event) =>
                    onupdate("estimateTime", event.target.value)
                  }
                />
              </li>
            </>
          )}
          <li className="p-2">
            <input
              className={`form-control ${
                invalidFields && invalidFields.spentTime && "invalid-field"
              }`}
              type="number"
              onWheel={(e) => e.target.blur()}
              placeholder="Spent Hours"
              value={spentTime}
              onChange={(event) => onupdate("spentTime", event.target.value)}
            />
          </li>
        </ul>
      )}
    </div>
  );
};

export default EstimateSpentTime;
