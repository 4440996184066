import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReportWithTaskDetails } from "../../api/apiCalls";

export const getReports = createAsyncThunk(
  "reports/getReports",
  async (data, thunkApi) => {
    const { startDate, endDate, userId } = data;
    try {
      const response = await getReportWithTaskDetails(
        startDate,
        endDate,
        userId
      );
      return { data: response };
    } catch (e) {
      return thunkApi.rejectWithValue(e.message);
    }
  }
);
