import React from "react";
import { useAlert } from "../../context/AlertContext";
import "./Alert.css";

const Alert = (props) => {
  const { alert } = useAlert();
  if (alert.isVisible) {
    return (
      <div className={`alert-container ${props.className}`}>
        <div
          className={`alert-body card-body ${
            alert.error ? "alert-error" : "alert-success"
          }`}
        >
          <div className="alert-heading">
            <i
              className={`bi bi-${
                alert.error ? "exclamation-octagon" : "check-circle"
              } me-2`}
            ></i>
            <span>{alert.error ? "Error" : "Success"}</span>
          </div>
          <div className="alert-message">{alert.message}</div>
        </div>
      </div>
    );
  }
};

export default Alert;
