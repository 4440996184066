import serverApiCall from "./serverApiCalls";
class _fakeApiCall {
  constructor() {
    this.v = 100; //just to make compiler happy
  }

  async getUserInfo() {
    return {
      name: "Shashikant",
      designation: "Web Designer",
      isAdmin: true,
      imgURL:
        "https://media.licdn.com/dms/image/C4E03AQGriSgZNCIMfQ/profile-displayphoto-shrink_100_100/0/1516937028346?e=1707955200&v=beta&t=7foABaGOF5Cxo6UnX1NaSEB5PG-8dZsQLcsDHPAyPuw",
    };
  }

  async getReportsStats() {
    return {
      ExpectedReports: "3",
      NumberOfSubmittedReports: "1",
      ReportSubmittedDates: [
        "2024-03-05T00:00:00"
      ]
    }
  }
  async getReportsData(filter) {
    console.log(filter);
    return filter
      ? [
          {
            id: 2,
            date: "12/01/2024",
            submitted: true,
            postedOn: "28/01/2024",
            sentTo: ["Anil", "Sumit"],
            linkedTasks: [
              {
                date: "Today's Task",
                tasks: [
                  { name: "Task 1", status: "onhold", description: "" },
                  { name: "Task 2", status: "created", description: "" },
                  { name: "Task 3", status: "open", description: "" },
                ],
              },
              {
                date: "Tomorrow's Task",
                tasks: [
                  { name: "Task 1", status: "closed", description: "" },
                  { name: "Task 2", status: "help", description: "" },
                  {
                    name: "Task 3",
                    status: "Any Help",
                    description: "Need Help in task 3",
                  },
                ],
              },
            ],
          },
        ]
      : [
          { id: 1, date: "05/12/2023", submitted: false },
          {
            id: 2,
            date: "04/12/2023",
            submitted: true,
            postedOn: "08/12/2023",
            sentTo: ["Anil", "Sumit"],
            linkedTasks: [
              {
                date: "Today's Task",
                tasks: [
                  { name: "Task 1", status: "open", description: "" },
                  { name: "Task 2", status: "onhold", description: "" },
                  { name: "Task 3", status: "created", description: "" },
                ],
              },
              {
                date: "tomorrow's Task",
                tasks: [
                  { name: "Task 1", status: "closed", description: "" },
                  { name: "Task 2", status: "open", description: "" },
                  {
                    name: "Task 3",
                    status: "help",
                    description: "Need Help in task 3",
                  },
                ],
              },
            ],
          },
        ];
  }
  async postReport(report) {
    console.log(report);
    return {
      reportDate: "2021-02-04",
      todayTasks: [
        {
          comment: ["Help Required"],
          id: "65c331dc3c8bdfab3dab8325",
          status: "helpRequired",
          title: "Task - 2",
          user: { id: "65c1f968efae4fb838f29a26" },
        },
        {
          comment: [],
          id: "65c47a1e3c8bdfab3dab833c",
          status: "inprogress",
          title: "Task - 3",
          user: { id: "65c1f968efae4fb838f29a26" },
        },
        {
          comment: [],
          id: "65c9addaece95cc91b613658",
          status: "inprogress",
          title: "hello",
          user: { id: "65a12741dadf39ba9a809de9" },
        },
      ],
      tomorrowTasks: [
        {
          comment: [],
          id: "65b0e84d0d3532b39bf33eff",
          status: "created",
          title: "Test task 1",
          user: { id: "65aa51446c25779d21c34657" },
        },
        {
          comment: [],
          id: "65ba2a28f3b01c43a80792dd",
          status: "onhold",
          title: "task 45",
          user: { id: "65a12741dadf39ba9a809de9" },
        },
      ],
      user: { id: "65a12741dadf39ba9a809de9" },
    };
  }
  async getUsers(filter) {
    console.log(filter);
    if(filter){
      return [
        {
          code: "203",
          id: "203",
          name: "Dave Joe",
          isActive: true,
          email: filter.email,
          role: "Admin",
          manager: "Test user",
        },
      ]
    }
    else {
      return [
        {
          code: "190",
          id: "190",
          name: "Shashi Kant",
          isActive: true,
          email: "shashi@example.com",
          notification_email: ["136", "862"],
          role: "standard",
          manager: "Anil",
        },
        {
          code: "136",
          id: "136",
          name: "Sumit",
          isActive: true,
              email: "sumit@example.com",
              notification_email: ["136", "862"],
              role: "admin",
              manager: "Anil",
            },
            {
              code: "862",
              id: "862",
              name: "Prashant Singh",
              isActive: false,
              email: "prashant@example.com",
              notification_email: ["136", "862"],
              role: "standard",
              manager: "Sumit",
            },
            {
              code: "180",
              id: "180",
              name: "Anil",
              isActive: true,
              email: "anil@example.com",
              notification_email: ["136", "862"],
              role: "admin",
              manager: "Sumit",
            },
        ];
    }
  }
  async getActiveUsers(){
    return [
      {
        code: "136",
        name: "Sumit",
      },
      {
        code: "180",
        name: "Anil",
      },
      {
        code: "862",
        name: "Prashant",
      },
      {
        code: "190",
        name: "Shashi Kant",
      },
      {
        code: "203",
        name: "Dave Joe",
      },
    ]
  }
  async putUser(userInfo){
    console.log(userInfo);
    return {
      "email": "prashant.singh181@gmail.com",
      "id": "65a12741dadf39ba9a809de9",
      "manager": "65a60e95bfbf10dde5095632",
      "name": "Prashant",
      "notification_email": [
        "65c33fe63c8bdfab3dab8328"
      ],
      "role": "admin"
    };
  }
  async getActiveTaskList(filter){
    console.log(filter)
    return [
      {taskId: "12345", name: "Task 1", status: "open", description: ""},
      {taskId: "23456", name: "Task 2", status: "help", description: "Some help"},
      {taskId: "34567", name: "Task 3", status: "created", description: ""},
      {taskId: "45678", name: "Task 4", status: "onhold", description: ""}
  ];
  }
  async authenticate(){
    return{
        role: "Admin",
        name: "Prashant",
        email: "prashant@example.com",
        manager: "Sumit",
        isActive: true,
        code: 136,
        token: "123"
      }
  }

  async getTaskDetails(){
    const tasksArray = [
      [
        {
          "comment": [],
          "id": "65e80ab4b106883c509003fe",
          "status": "completed",
          "title": "Back date reporting",
          "user": {
            "id": "65e60e5dbf23084eacf3d9b5"
          }
        },
        {
          "comment": [],
          "id": "65e83f5eb106883c50900401",
          "status": "completed",
          "title": "Task 3",
          "user": {
            "id": "65e60e5dbf23084eacf3d9b5"
          }
        },
        {
          "comment": [],
          "id": "65e83f60b106883c50900402",
          "status": "completed",
          "title": "Task 2",
          "user": {
            "id": "65e60e5dbf23084eacf3d9b5"
          }
        },
        {
          "comment": [
            "Just testing.",
          ],
          "id": "65e83f7ab106883c50900403",
          "status": "completed",
          "title": "Task 4",
          "user": {
            "id": "65e60e5dbf23084eacf3d9b5"
          }
        }
      ]
    ]
    return tasksArray[Math.floor(Math.random()*5)];
  }
  async getSubordinateReports(filter){
    console.log(filter);
    return filter ? [
      {
        "createdDate": "2024-01-30T11:18:52.269000Z",
        "id": "65b8db1cf3b01c43a80792c6",
        "reportDate": "2024-01-01T00:00:00Z",
        "todayTasks": [
          {
            "id": "65b0e8b00d3532b39bf33f01",
            "status": "inprogress"
          },
          {
            "id": "65b22fee49eb1f9aed992c5e",
            "status": "completed"
          },
        ],
        "tomorrowTasks": [
          {
            "id": "65b0e84d0d3532b39bf33eff",
            "status": "completed"
          },
          {
            "id": "65b0e84d0d3532b39bf33eff",
            "status": "helpRequired"
          }
        ],
        "user": "123"
      },
      {
        "createdDate": "2024-01-30T11:18:52.269000Z",
        "id": "65b8db1cf3b01c43a80792c6",
        "reportDate": "2024-01-01T00:00:00Z",
        "todayTasks": [
          {
            "id": "65b0e8b00d3532b39bf33f01",
            "status": "inprogress"
          },
          {
            "id": "65b22fee49eb1f9aed992c5e",
            "status": "completed"
          },
        ],
        "tomorrowTasks": [
          {
            "id": "65b0e84d0d3532b39bf33eff",
            "status": "created"
          },
          {
            "id": "65b0e84d0d3532b39bf33eff",
            "status": "onhold"
          }
        ],
        "user": "456"
      },
    ]
  : [
    {
      "createdDate": "2024-01-30T11:18:52.269000Z",
      "id": "65b8db1cf3b01c43a80792c6",
      "reportDate": "2024-01-01T00:00:00Z",
      "todayTasks": [
        {
          "id": "65b0e8b00d3532b39bf33f01",
          "status": "inprogress"
        },
        {
          "id": "65b22fee49eb1f9aed992c5e",
          "status": "completed"
        },
      ],
      "tomorrowTasks": [
        {
          "id": "65b0e84d0d3532b39bf33eff",
          "status": "created"
        }
      ],
      "user": "456"
    },
    {
      "createdDate": "2024-01-30T11:21:14.937000Z",
      "id": "65b8dbaaf3b01c43a80792c8",
      "reportDate": "2024-01-02T00:00:00Z",
      "todayTasks": [
        {
          "id": "65b0e8b00d3532b39bf33f01",
          "status": "helpRequired"
        },
        {
          "id": "65b22fee49eb1f9aed992c5e",
          "status": "completed"
        }
      ],
      "tomorrowTasks": [
        {
          "id": "65b0e84d0d3532b39bf33eff",
          "status": "onhold"
        },
        {
          "id": "65b8dbaaf3b01c43a80792c7",
          "status": "helpRequired"
        }
      ],
      "user": "789"
    },
  ]
  }

  async getSubordinates(){
    return [
      {
        id: "123",
        name: "Prashant",
      },
      {
        id: "456",
        name: "Rajesh",
      },
      {
        id: "789",
        name: "Shashikant",
      },
    ]
  }
}

// const apiCaller = new _fakeApiCall();
//const apiCaller = new serverApiCall("http://3.110.85.223:8081/api/v3");
//const apiCaller = new serverApiCall("https://dailybackend.poditservices.com/api/v3");
const apiCaller = new serverApiCall(process.env.REACT_APP_API_BASE_URL);

export async function getUserInfo() {
  const value = await apiCaller.getUserInfo();
  return value;
}

export async function getReportsStats(startDate, endDate, userId) {
  const value = await apiCaller.getReportsStats(startDate, endDate, userId);
  return value;
}

export async function getAdminStatsCall(startDate, endDate, userId) {
  const value = await apiCaller.getAdminStats(startDate, endDate, userId);
  return value;
}

export async function getReportsData(filter) {
  const value = await apiCaller.getReportsData(filter);
  return value;
}

export async function getReportWithTaskDetails(startDate, endDate, userId) {
  const value = await apiCaller.getReportWithTaskDetails(startDate, endDate, userId);
  return value;
}

export async function getReportById(reportId) {
  const value = await apiCaller.getReportById(reportId);
  return value;
}

export async function postReport(report) {
  const value = await apiCaller.postReport(report);
  return value;
}

export async function getUsers(filter) {
  const value = await apiCaller.getUsers(filter);
  return value;
}

export async function createUser({name, email, role, manager, notification_email}) {
  const value = await apiCaller.createUser({name, email, role, manager, notification_email});
  return value;
}

export async function deleteUsers(usersList) {
  const value = await apiCaller.deleteUsers(usersList);
  return value;
}

export async function getActiveUsers(){
  const value = await apiCaller.getActiveUsers();
  return value;
}

export async function putUser(userInfo){
  const value = await apiCaller.putUser(userInfo);
  return value;
}

export async function getActiveTaskList(filter) {
  const value = await apiCaller.getActiveTaskList(filter);
  return value;
}

export async function authenticate() {
  const value = await apiCaller.authenticate();
  return value;
}

export async function getSubordinateReports(filter) {
  const value = await apiCaller.getSubordinateReports(filter);
  return value;
}

export async function getSubordinateReportsWithTaskDetails(startDate, endDate, users) {
  const value = await apiCaller.getSubordinateReportsWithTaskDetails(startDate, endDate, users);
  return value;
}

export async function getSubordinates(queryObject) {
  const value = await apiCaller.getSubordinates(queryObject);
  return value;
}

export async function getTaskDetails(id){
  const value = await apiCaller.getTaskDetails(id)
  return value
}

export async function updateTask(taskInfo) {
  const value = await apiCaller.updateTask(taskInfo);
  return value;
}

export async function getTimeSheet(requestObject) {
  const value = await apiCaller.getTimeSheet(requestObject);
  return value;
}