import "./task.css"
import React, { useState } from "react";
import { displayStatus } from "../../constant/statusMap";
import { updateTask } from "../../api/apiCalls";
import { useAlert } from "../../context/AlertContext";
import { useApiCall } from "../../middleware/useApiCall";

const Task = (props) => {
  const apiCallMiddleware = useApiCall();
  const { showErrorAlert, showSuccessAlert } = useAlert();

  const [showCommentDropdown, setShowCommentDropdown] = useState(false);
  const [replyComment, setReplyComment] = useState("");

  function renderHelpSection() {
    if (props.showDescription && props.description && props.description.length > 0) {
      return (
        <div className="task-description py-1 mx-3 my-1 ">
          <ol className="m-0">
            {props.description.map((comment, i) => <li key={i}>{comment}</li>)}
          </ol>
        </div>
      );
    }
  }

  async function handleCommentSend() {
    try {
      const task = await apiCallMiddleware(updateTask, {
        id: props.taskId,
        reportId: props.reportId,
        comment: [replyComment],
      });
      if(task instanceof Object){
        showSuccessAlert("Comment sent successfully!");
        setShowCommentDropdown(false);
        setReplyComment("");
      } else {
        showErrorAlert("Comment couldn't be sent! Please try again later.");
      }
    } catch (error) {
      console.error(error);
      showErrorAlert(error.message);
    }
  }
  return (
    <div className="border-top border-1">
      <div className="d-flex justify-content-between py-1 px-3">
        <span>{props.name}</span>
        <div className="d-flex flex-row justify-content-between">
          <span className="report-status">{displayStatus(props.status)}</span>
          {props.showCommentInput && (
            <button
              className="reply-btn button"
              onClick={() =>
                setShowCommentDropdown(
                  (prevShowCommentDropdown) => !prevShowCommentDropdown
                )
              }
            >
              <i className="bi bi-reply"></i>
            </button>
          )}
        </div>
      </div>
      {/* Comment Dropdown */}
      {showCommentDropdown && (
        <div className="reply-container px-3 my-2">
          <div className="reply-div d-flex flex-row justify-content-between align-items-center gap-1">
            <textarea
              className="reply-input border-0 ms-1 flex-grow-1"
              placeholder="Comment"
              value={replyComment}
              onChange={(event) => setReplyComment(event.target.value)}
              autoFocus
            ></textarea>
            <button className="send-btn" onClick={handleCommentSend}>
              Save
            </button>
          </div>
        </div>
      )}
      {/* Existing comments in task */}
      {renderHelpSection()}
    </div>
  );
};

export default Task;
