import { createSlice } from "@reduxjs/toolkit";
import { getSubordinates } from "./actions";

const subordinatesSlice = createSlice({
    name: "subordinates",
    initialState: [],
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSubordinates.fulfilled, (_, action) => action.payload)
    }
})

export default subordinatesSlice.reducer