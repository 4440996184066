import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import Alert from "../components/Alert/Alert";
import TourComponent from "../components/TourComponent";
import { useTour } from "@reactour/tour";

const DashboardLayout = () => {
  const { setIsOpen } = useTour();
  return (
    <div className="App">
      <Header  helpAction={() => setIsOpen(true)} showUserIcon={true}/>
      <Navbar />
      <Outlet />
      <Alert />
      <TourComponent />
    </div>
  );
};

export default DashboardLayout;
