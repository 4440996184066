import React from "react";
import LicensePriceCard from "../components/LicensePriceCard";
import Faq from "../components/Faq";
import { useKeycloak } from "@react-keycloak/web";

function License() {
  const { keycloak } = useKeycloak();
  return (
    <>
      <div className="container mt-5 px-lg-5">
        <main className="py-5">
          <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
            <h1 className="display-4 fw-normal text-body-emphasis">
              Liked it!!
            </h1>
            <h1 className="display-4 fw-normal text-body-emphasis">
              Go for the paid plan
            </h1>
          </div>
          <div className="d-flex row px-4 gap-4 justify-content-center ">
            <LicensePriceCard
              title="Free Trial Account"
              subtitle={["90 Days Trial"]}
              buttonText="Get started"
              onClick={() => keycloak.register()}
            />
            <LicensePriceCard
              title="Essentials"
              subtitle={["Unlimited Reports", "Unlimited Communication"]}
              buttonText="Contact Sales"
              onClick={() => window.open(process.env.REACT_APP_PODIT_CONTACT_US_URL, "_blank")}
            />
          </div>
          <div className="pricing-header p-3 pb-md-4 my-4 mx-auto text-center">
            <h3 className="display-6 fw-normal text-body-emphasis">
              Frequently Asked Questions
            </h3>
          </div>
          <Faq />
        </main>
      </div>
      <div className="bg-primary p-5 pb-md-4 mx-auto text-center">
        <h2 className=" container d-flex display-6 text-light my-5 justify-content-center   ">
          Do you want to get pricing or trial ?
        </h2>
        <div className="d-flex flex-column flex-md-row gap-4 pb-5 justify-content-center  ">
          <button type="button" className="btn btn-light text-primary fs-4 fw-bold px-4 py-2" 
            onClick={() => window.open(process.env.REACT_APP_PODIT_CONTACT_US_URL, "_blank")}>
            Contact Us
          </button>
          <button type="button" className="btn btn-outline-light fs-4 fw-bold px-4 py-2"
            onClick={() => keycloak.register()}
          >
            Sign up Now!
          </button>
        </div>
      </div>
    </>
  );
}

export default License;
