import React from "react";

const Card = (props) => {
  return (
    <div className="card info-card report-card">
      <div className="card-body">
        <h5 className="card-title">{props.heading}</h5>

        <div className="d-flex align-items-center">
          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center" style={props.style}>
            {props.icon}
          </div>
          <div className="ps-3 fw-bold">{props.value}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
