import { createSlice } from "@reduxjs/toolkit";
import { getReportStats, getAdminStats } from "./actions";

const initialState = {
    error: null,
    reportStats: {
        ExpectedReports: null,
        NumberOfSubmittedReports: null,
        ReportSubmittedDates: [],
        TotalUsers: null,
    }
};

export const statsSlice = createSlice({
    name: "stats",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getReportStats.fulfilled, (state, action) => {
            state.error = null
            state.reportStats = action.payload.data;
        });
        builder.addCase(getReportStats.rejected, (state, action) => {
            state.error = action.payload
        });
        builder.addCase(getAdminStats.fulfilled, (state, action) => {
            state.error = null
            state.reportStats = action.payload.data;
        });
        builder.addCase(getAdminStats.rejected, (state, action) => {
            state.error = action.payload
        });
    },
});

export default statsSlice.reducer;