import React from "react";

function MoreInfoButton({ onClick }) {
  return (
    <button
      type="button"
      className="btn border border-0"
      data-tooltip-id="manager-info"
      onClick={onClick}
    >
      <i className="bi bi-three-dots-vertical text-dark"></i>
    </button>
  );
}

export default MoreInfoButton;
