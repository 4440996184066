import { commentFormatter } from "./taskCommentFormatter";
import { getTaskStatusForUI } from "../constant/statusMap";

export default function reportFormatter(report) {
  const tasks = [...report.todayTasks, ...report.tomorrowTasks];
  const formattedReport = {
    reportId: report.id,
    date: report.reportDate,
    submitted: true,
    postedOn: report.createdDate,
    sentTo: report.user.notification_email,
    linkedTasks: [
      {
        date: "Tasks",
        tasks: tasks?.map((task) => ({
          taskId: task.id,
          name: task.title,
          description: task.comment ? commentFormatter(task.comment) : [],
          status: getTaskStatusForUI(task.status),
        })),
      },
    ],
    user: report.user,
  };
  return formattedReport;
}
