import { configureStore } from "@reduxjs/toolkit";
import statsReducer from "./stats/reducer";
import reportsReducer from "./reports/reducers";
import subordinateReportsReducer from "./subordinateReports/reducers";
import subordinatesReducer from "./subordinates/reducers"
import timeSheetReducer from "./timeSheet/reducers"

export default configureStore({
  reducer: {
    stats: statsReducer,
    reports: reportsReducer,
    subordinateReports: subordinateReportsReducer,
    subordinates: subordinatesReducer,
    timeSheet: timeSheetReducer
  }
})