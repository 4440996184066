import React from "react";
import ReportCard, { ReportCardCreator } from "../components/ReportCard";


const ReportCardList = (props) => {

  return (
    <section className="section">
      {props.reportsData.map((report, i) =>
        report.submitted ? (
          <ReportCard key={i} {...report} subordinateReport={props.subordinateReport} />
        ) : (
          <ReportCardCreator key={i} {...report} subordinateReport={props.subordinateReport} />
        )
      )}
    </section>
  );
};


//FIXME: need to make single component in this file.
export const SubordinateReportCardList = props => {
  return (
    <section className="section">
      {props.reportsData.map((report, i) =>
        report.submitted ? (
          <ReportCard key={i} {...report} subordinateReport={true} />
        ) : (
          <ReportCardCreator key={i} {...report}/>
        )
      )}
    </section>
  )
}

export default ReportCardList;
